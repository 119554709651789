import React from 'react';
import PropTypes from 'prop-types';
import {Button as MuiButton} from '@mui/material';

import styles from './Button.module.css';
import classNames from 'classnames';
import {ButtonType} from './modal';

const Button = (props) => {
    const {sx, disabled, text, color, type = ButtonType.oval, callback, children, variant} = props;

    const classes = classNames(styles.button, {
        [styles.circle]: type === ButtonType.circle,
        [styles.square]: type === ButtonType.square,
        [styles.oval]: type === ButtonType.oval,
        [styles.transparent]: type === ButtonType.transparent,
    });

    return (
        <MuiButton
            sx={sx}
            disabled={disabled}
            className={classes}
            variant='contained'
            color={color}
            onClick={(event) =>
                callback ? callback(event) : console.log('Callback is empty')
            }
        >
            {text || children}
        </MuiButton>
    );
};

Button.propTypes = {
    text: PropTypes.string,
    color: PropTypes.string,
    type: PropTypes.string,
    variant: PropTypes.string,
    callback: PropTypes.func,
    children: PropTypes.node,
    disabled: PropTypes.bool,
};

export default Button;
