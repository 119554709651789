import { Typography } from '@mui/material';
import React from 'react';
import UI from '../../../shared/component/ul/Ul';

export const ExpertiseDictionary = {
  AuthorExpertise: 'authorExpertise',
  BallisticExpertise: 'ballisticExpertise',
  AccountingExpertise: 'accountingExpertise',
  AideotechnicalExpertise: 'aideotechnicalExpertise',
  GemologicalExpertise: 'gemologicalExpertise',
  GraphologicalExpertise: 'graphologicalExpertise',
  LandManagementExpertise: 'landManagementExpertise',
  StudyOfChineseCharacterExpertise: 'studyOfChineseCharacterExpertise',
  ComputerTechnicalExpertise: 'computerTechnicalExpertise',
  LinguisticExpertise: 'linguisticExpertise',
  FireTechnicalExpertise: 'fireTechnicalExpertise',
  HandwritingExpertise: 'handwritingExpertise',
  PsychologicalExpertise: 'psychologicalExpertise',
  RadioEngineeringExpertise: 'radioEngineeringExpertise',
  TechnicalExpertise: 'technicalExpertise',
  DocumentsExpertise: 'documentsExpertise',
  EconomicExpertise: 'economicExpertise',
  PhonoscopicExpertise: 'phonoscopicExpertise',
  PhototechnicalExpertise: 'phototechnicalExpertise',
  NatureExpertise: 'natureExpertise',
  MaterialsExpertise: 'materialsExpertise',
  ElectricalExpertise: 'electricalExpertise',
};

export const ExpertiseList = [
  {
    title: 'Автороведческая экспертиза',
    hiddenAfterCount: 4,
    collapsed: true,
    description: [
      <Typography
        sx={{ fontSize: '1.2rem', m: '.5rem 0' }}
        children='Задачей автороведческой экспертизы является установление автора документа на основе признаков письменной речи.'
      />,
      <Typography
        sx={{ fontSize: '1.2rem', m: '.5rem 0' }}
        children='Вопросы, решаемые автороведческой экспертизой:'
      />,

      <UI
        type='pointer'
        items={[
          `Является ли автором текста документа конкретное лицо?`,
          `Является ли автором текстов нескольких документов (или не­кольких фрагментов текста одного документа) одно лицо?`,
          `Выполнен ли текст документа с намеренным искажением письменно - речевого навыка?`,
          `Исполнен ли представленный на исследование текст документа с намеренным подражанием письменно - речевым навыкам автора?`,
          `Каковы характеристические данные автора текста: родной или преобладающий язык, место формирования его речевых навы­ков, уровень грамотности, профессия?`,
        ]}
      />,
      <Typography
        sx={{ fontSize: '1.2rem', m: '.5rem 0' }}
        children='Свободные образцы письменной речи предполагаемого автора (авторов) представляются в объеме не менее 10—15 листов в виде текстов документов (писем, дневников, автобиографий, докладных и объяснительных записок, черновых записей), составленных до возбуждения уголовного дела. Образцы должны соответствовать исследуемому документу по времени исполнения, языку, функцио­нальному стилю речи.'
      />,
      <Typography
        sx={{ fontSize: '1.2rem', m: '.5rem 0' }}
        children='В качестве экспериментальных образцов представляются сочи­нения предполагаемого автора, написанные на тему, избранную им самим, а также на тему, предложенную следователем. Отбор экс­периментальных образцов производится в несколько этапов на од­ну и ту же или сходные темы. Их общий объем должен быть не менее 20—25 листов.'
      />,
    ],
    img: '',
  },
  {
    title: 'Баллистическое исследование',
    hiddenAfterCount: 4,
    collapsed: true,
    description: [
      <Typography
        sx={{ fontSize: '1.2rem', m: '.5rem 0' }}
        children='Баллистическое исследование проводится в целях установления относимости предметов к огнестрельному оружию; определения исправности оружия и патронов, и их пригодность к производству выстрела(ов); определения вида, системы, модели ору­жия; отождествления его по следам на снарядах (пулях, дроби, картечи) и гильзах; обстоятельств выстрела (направление и рас­стояние выстрела, местонахождение стрелявшего и др.), относимости повреждений к огнестрельным, а также решения ряда иных вопросов, связанных с огнестрельным оружи­ем и следами его применения.'
      />,
      <Typography
        sx={{ fontSize: '1.2rem', m: '.5rem 0' }}
        children='В зависимости от объектов исследования различают следующие основные баллистические экспертизы:'
      />,

      <UI
        type='pointer'
        items={[
          `огнестрельного оружия промышленного и кустарного производства;`,
          `самодельного огнестрельного оружия, стреляющих устройств, а также предметов, имеющих отдельные конструктивные признаки огнестрельного оружия или сходных с ним по внешнему виду;`,
          `газового оружия;`,
          `пневматического оружия;`,
          `сигнальных пистолетов, револьверов и устройств;`,
          `макетов оружия;`,
          `хозяйственно-бытовых устройств и инструментов, а также объектов специального назначения, имеющих признаки оружия;`,
          `огнестрельных повреждений и следов выстрела на отдельных преградах;`,
          `патронов.`,
        ]}
      />,
      <Typography
        sx={{ fontSize: '1.2rem', m: '.5rem 0' }}
        children='Вопросы, решаемые при исследовании огнестрельного оружия (его материальной части, баллистических характеристик):'
      />,

      <UI
        type='pointer'
        items={[
          `Относится ли представленный предмет к огнестрельному оружию? Если да, то к какому виду, образцу (модели)?`,
          `Где и когда (предприятие-изготовитель, фирма, год выпус­ка) изготовлено представленное оружие?`,
          `Какому виду, образцу (модели) оружия принадлежат пред­ставленные детали (части); не являются ли они деталями конкрет­ного экземпляра оружия (оружия с определенным номером либо оружия, представленного вместе с деталями)?`,
          `Исправно ли представленное оружие? Если неисправно, то в чем состоят неисправности, какова причина их возникновения, как они влияют на возможность использования оружия (при стрельбе, других операциях)?`,
          `Пригодно ли оружие к стрельбе?`,
          `Каким способом (промышленным, самодельным) изготовлено оружие (его отдельные детали)?`,
          `Не внесены ли изменения в конструкцию представленного ору­жия? Если да, то какие именно, с какой целью, каким способом (промышленным, самодельным)?`,
          `Какие материалы, предметы использованы при изготовлении самодельного оружия или при изменениях устройства оружия промыш­ленного изготовления, какие технические средства, инструменты при этом применялись?`,
          `Судя по признакам изготовления самодельного оружия, ка­кими могли быть квалификация, профессиональные навыки лица, его изготовившего?`,
          `Возможны ли из представленного оружия выстрелы без воздействия на спусковой крючок (при определенных условиях ли­бо при различных возможных условиях)?`,
          `Каковы тактико-технические и баллистические характеристики представленного оружия (основные либо отдельные из них)?`,
          `Каким снарядом (пулей, дробью, картечью) произведен пос­ледний выстрел из гладкоствольного ружья?`,
          `Производился ли выстрел из оружия после последней чист­ки канала ствола?`,
          `Каким порохом (вид, марка) произведен последний выст­рел из оружия?`,
          `Каково первоначальное содержание уничтоженных (спилен­ных, забитых и т. п.) маркировочных обозначений (номера), имев­шихся на оружии?`,
        ]}
      />,
      <Typography
        sx={{ fontSize: '1.2rem', m: '.5rem 0' }}
        children='Вопросы, решаемые при исследовании боеприпасов (патронов), их элементов (материальной части, технического состояния, баллистических характеристик):'
      />,

      <UI
        type='pointer'
        items={[
          `К какому виду (типу, образцу и т. п.) относятся представ­ленные патроны; для стрельбы из какого оружия они предназна­чены?`,
          `Каким способом (промышленным, самодельным) изготовле­ны представленные патроны или их отдельные элементы?`,
          `К патронам какого вида (типа, образца, назначения и т. п.) относятся представленные элементы (пули, гильзы, пыжи, кап­сюли)?`,
          `Не внесены ли в устройство патро­нов (размер, форма и др.) или их отдельных элементов (пуль, гильз) изменения с целью использования их для стрельбы из оружия, для которого они не предназначены; в чем эти изменения состоят; для стрельбы из какого оружия могут применяться эти патроны после внесенных изменений?`,
          `Пригодны ли представленные патроны для стрельбы?`,
          `Каковы технические и баллистические характеристики пред­ставленных патронов (основные либо отдельные из них), соответ­ствуют ли они нормам, установленным для патронов данного типа. Если нет, то каковы возможные причины этого?`,
          `Где и когда (предприятие-изготовитель, фирма, год изготов­ления) изготовлены патроны (или их гильзы)?`,
          `Не изготовлены ли представленные патроны (их элементы) с использованием однотипных или одних и тех же материалов, тех­нических средств, инструментов, технологических операций?`,
          `Не изготовлены ли патроны (их элементы) с применением материалов, инструментов, изъятых в определенном месте (у определенного лица)?`,
        ]}
      />,
      <Typography
        sx={{ fontSize: '1.2rem', m: '.5rem 0' }}
        children='Вопросы, решаемые при исследовании следов выстрела (следов оружия на пулях, гильзах и других элементах боеприпасов; следов выстрела на пораженных объектах):'
      />,

      <UI
        type='pointer'
        items={[
          `Из оружия какого вида (образца, калибра) выстрелена пу­ля, представленная на исследование?`,
          `Из оружия какого вида (образца, калибра) произведен выст­рел патроном, гильза которого представлена на исследование?`,
          `Из гладкоствольного оружия какого калибра выстрелена дробь (картечь), представленная на исследование?`,
          `Не выстреляны ли представленные пули из одного экземпляра оружия?`,
          `Не стреляны ли представленные гильзы в одном экземпляре ору­жия?`,
          `Не выстрелена ли представленная пуля (дробь, картечь) из представленного оружия?`,
          `Не стреляна(ны) ли представленная(ные) гильза(ы) в представленном экземпляре оружия?`,
          `Не выстрелена ли представленная пуля из оружия несоот­ветствующего (большего или меньшего) калибра, либо оружия с измененным устройством, техническими дефектами?`,
          `Не использован ли патрон, гильза которого представлена на исследование, для выстрела из оружия, предназначенного для стрельбы патронами иного типа (калибра), либо оружия с изме­ненным устройством, техническими дефектами?`,
          `Не составляли ли до выстрела единый патрон представленные на исследо­вание пуля и гильза?`,
          `Какая из представленных пуль (выстрелянных из одного экземпляра оружия) выстрелена первой?`,
          `Является ли представленный предмет элементом патрона (пулей, дробью, пыжом, их частью), использованного при выстре­ле? Если да, то к патрону какого типа (калибра и т.п.) он принадле­жал?`,
          `Какова причина деформации (разделения на части, измене­ния формы) пули, представленной на исследование?`,
          `Не рикошетировала ли, не проходила ли через преграды представленная пуля до попадания в пораженный объект? Если да, то каким мог быть вид (материал и т. п.) этой преграды?`,
          `Является ли повреждение на пораженном объекте огне­стрельным?`,
          `Выстрелом из оружия какого вида, снарядом какого вида (калибра, устройства, назначения) образовано огнестрельное пов­реждение?`,
          `Каким количеством выстрелов образованы огнестрельные повреждения на представленном объекте?`,
          `Какова очередность образования нескольких огнестрельных повреждений?`,
          `Какие из огнестрельных повреждений на объекте являются входными и выходными?`,
          `Каково направление огнестрельного канала (каналов) в по­раженном объекте (в горизонтальной и вертикальной плоскостях, по отношению к окружающей обстановке и т. п.)?`,
          `Какими могли быть (с учетом признаков следов выстрела, других обстоятельств) взаимное расположение потерпевшего и стрелявшего, поза потерпевшего?`,
          `Имеются ли на представленном объекте следы близкого выстрела?`,
          `С какого расстояния произведен выстрел, которым образо­вано повреждение на объекте?`,
          `С какого места (участка местности, участка места проис­шествия) был произведен выстрел?`,
          `Какова давность выстрела патроном, гильза которого пред­ставлена на исследование?`,
        ]}
      />,
      <Typography
        sx={{ fontSize: '1.2rem', m: '.5rem 0' }}
        children='При назначении баллистического исследования в распоряжение экспертов представляется следующее:'
      />,

      <UI
        type='pointer'
        items={[
          `пули, гильзы и патроны со следами оружия, обнаруженные на месте происшествия;`,
          `огнестрельное оружие и объекты с огнестрельными поврежде­ниями;`,
          `протоколы осмотра и схемы с указанием места обнаружения пуль, гильз, огнестрельного оружия, объектов с огнестрельными повреждениями;`,
          `боеприпасы и огнестрельное оружие, изымаемые у подозревае­мых лиц;`,
          `акт судебно-медицинского исследования трупа;`,
          `одежда потерпевшего с огнестрельными повреждениями;`,
          `материалы, инструменты и приспособления, изымаемые у подозреваемых лиц.`,
        ]}
      />,
      <Typography
        sx={{ fontSize: '1.2rem', m: '.5rem 0' }}
        children='Оружие на исследование направляется в том состоянии, в ка­ком обнаружено. Заряженное оружие необходимо разрядить, указав об этом в постановлении о назначении экспертизы. Объекты со следами выстрела (одежда, части пораженных выстрелом пред­метов) должны быть упакованы таким образом, чтобы максималь­но уберечь их от различных посторонних воздействий (трения, из­гибания). Сами следы следует защитить дополнительными средст­вами (прокладками, обшивкой тканью). Объекты с повышенной влажностью должны быть предварительно просушены без применения нагревательных приборов.'
      />,
    ],
    img: '',
  },
  {
    title: 'Бухгалтерская экспертиза',
    hiddenAfterCount: 4,
    collapsed: true,
    description: [
      ...[
        'Бухгалтерская экспертиза проводится с целью  выяснения фактических данных и обстоятельств, имеющих доказательственное значение, в связи с расследованием и рассмотрением уголовных и гражданских дел.',
        `Предметом бухгалтерской экспертизы являются данные бухгалтерского учета, отражающие хозяйственные операции, в связи с которыми в ходе расследования или судебного разбирательства перед экспертом-бухгалтером поставлены вопросы, требующие специальных познаний в области бухгалтерского учета. Основу предмета судебно-бухгалтерской экспертизы составляет исследуемый экспертом способ ведения бухгалтерского учета, установления необычных условий и приемов совершения учетных операций и записей (искажающие факторы), места, времени, механизма, способа, качественной и количественной характеристик искажения учетных данных.`,
        `Объектами бухгалтерской экспертизы являются документы бухгалтерского оформления (учетные регистры, финансовые отчеты), а также первичные документы. В ряде случаев при даче заключения эксперты используют информацию из иных материалов дела (протоколов допросов, протоколов осмотра, заключений специалистов других отраслей знаний, деловой переписки, справок и т.п.).`,
        `К числу основных задач бухгалтерской экспертизы можно отнести:`,
      ].map((el) => (
        <Typography sx={{ fontSize: '1.2rem', m: '.5rem 0' }} children={el} />
      )),

      <UI
        type='pointer'
        items={[
          `выявление фактов искажения учетных данных и определение их характера, а также степени влияния на интересующие следствие (суд) финансово-экономические показатели деятельности предприятия (организации);`,
          `определение тождественности экономической сущности и характера «Черновых записей» и данных официального «Белого» учета предприятия;`,
          `создание модели (реконструкция) отсутствующих или искаженных величин, записей, форм бухгалтерской отчетности на основе предоставленных в распоряжение эксперта объектов исследования  и т.д.;`,
          `определение и анализ недостатков в системе бухгалтерского учета и отчетности.`,
        ]}
      />,
      ...[
        `Эксперт-бухгалтер может решать также и другие задачи, связанные с несоблюдением требований бухгалтерского учета и контроля, финансовой, кассовой и расчетной дисциплины, если для этого необходимо применение его специальных познаний.`,
        `До назначения бухгалтерской экспертизы в процессе расследования уголовного дела должны быть выявлены конкретные факты (операции), которые в совокупности составят характеристику преступного деяния и позволят дать его экономическую и стоимостную оценку. Для выявления и конкретизации подобных фактов требуется подробный анализ и проверка финансовой документации предприятия или аудита. Причем по многим категориям уголовных дел документальная ревизия (аудиторская проверка) должна носить комплексный характер, предполагающий участие в анализе документов не только бухгалтера-ревизора, но и финансистов, технологов, товароведов, специалистов по ценообразованию и т.п.`,
        `Правильно и подробно сформулированное задание, поставленное перед ревизорами (аудиторами), позволяет максимально точно определить последующий круг вопросов экспертизы, систематизировать документы, которые в последствии явятся объектами экспертного исследования.`,
        `Следует отметить, что предлагаемый перечень вопросов не является исчерпывающим, поэтому, решая вопрос о назначении того или иного вида экспертизы в рамках расследования конкретного уголовного дела, необходимо исходить из существа выявленных ревизором (аудитором) фактов.`,
        `Примерный перечень вопросов, подлежащих рассмотрению при производстве бухгалтерской экспертизы:`,
      ].map((el) => (
        <Typography sx={{ fontSize: '1.2rem', m: '.5rem 0' }} children={el} />
      )),

      <UI
        type='pointer'
        items={[
          `Определить за счет каких источников отражено в бухгалтерском учете финансирование инвестиционной программы, обусловленной договором № … от … за период с … по … по перечислению средств в адрес (наименование фирм).`,
          `Соответствуют ли записи на счетах бухгалтерского учета (наименование организации) о расходах на выполнение обязательств перед вкладчиками за период с … по … данным, указанным в первичной документации? Если нет, привели ли указанные отклонения к завышению или занижению суммы произведенных расходов?`,
          `Соответствуют ли данные синтетического учета и отчетности (наименование организации) за период с … по … первичным документам по оприходованию денежных средств? Если нет, на какую сумму допущено искажение показателей отчетности об объемах привлеченных денежных средств?`,
          `Соответствуют ли записи в счетах  бухгалтерского учета (наименование организации) об оприходовании денежных средств, полученных от вкладчиков в период с … по … по договорам №№ данным, указанным в первичной документации? Если нет, привели ли указанные отклонения к завышению или занижению суммы оприходованных денежных средств?`,
          `Отражены ли в учете склада (№, наименование предприятия, наименование материалов) материальные ценности, поступившие по (наименование документов, дата)?`,
          `Соответствуют ли данные складского учета (наименование материалов) данным бухгалтерского учета?`,
          `Определить размер недостачи материалов на складе предприятия (наименование) по состоянию на (дата инвентаризации), с учетом  фактов неоприходования материалов по накладным №№ (даты) от фирмы (наименование).`,
          `Как изменится результат взаиморасчетов фирмы (наименование) с предприятием (наименование) с учетом поставок материалов, оформленных вышеперечисленными накладными? Каковы экономические последствия недостачи (изъятия из оборота фирмы) ценностей?`,
          `Подтверждается ли документально размер недостачи денежных средств в кассе (наименование организации), образовавшейся в результате неоприходования полученных в банке денежных средств по чекам №№ от … и необоснованного завышения в расходных кассовых ордерах №№  (даты) сумм оплаты ГСМ за период …`,
          `Имеется ли задолженность (наименование организации) перед поставщиками, в какой сумме и за какой товар? В чем она выразилась, какими документами подтверждается, период?`,
          `Определить по документальным данным и с учетом материалов следствия размер недостачи товарно-материальных ценностей в результате неоприходования их в (наименование организации) в период (по эпизодам), выявленным и указанным в акте ревизии?;`,
          `Соответствует ли Правилам ведения бухгалтерского учета оформление операций по расходованию денежных средств со счетов (наименование организации), произведенное по следующим платежным поручениям (№, дата)?`,
          `Соответствуют ли в (наименование организации) постановка учета товарно-материальных ценностей установленному порядку? Если нет, то оказало ли это влияние на образование недостачи и если да, то как именно?`,
          `Оформлялись ли отчетные документы по привлечению и выплате денежных средств в (наименование организации) с соблюдением установленных правил? Если нет, каким нормативным актам они противоречат?`,
          `Какие требования Правил ведения бухгалтерского учета не были выполнены (наименование организации) при оприходованию и выплате денежных средств по следующим документам?`,
          `Соблюдалась ли в (наименование организации) надлежащая методика ведения бухгалтерского учета при проведении хозяйственных операций, оформленных следующими документами (номер, дата)?`,
          `Соответствует ли постановка учета и отчетности в (наименование организации) требованиям Правил ведения бухгалтерского учета? Обеспечивает ли она действенный и своевременный  контроль за движением материальных ценностей и денежных средств? Если нет, какие отступления от Правил затрудняют выявление недостачи или делают это невозможным?`,
          `Правильно ли организована постановка бухгалтерского учета (наименование организации) и в какой мере она достоверна?`,
          `Соответствует ли Правилам ведения бухгалтерского учета оформление движения денежных средств в сумме … переведенных (наименование организации) платежным(и) поручением(ями) (номер, дата) во исполнение договора (номер, дата)?`,
          `Соответствует ли Правилам ведения бухгалтерского учета оформление операций по расходованию денежных средств со счетов (наименование организации)?`,
          `Имелись ли несоответствия в постановке учета материальных ценностей и денежных средств в (наименование организации)? Если имелись, то какие именно, чем они вызваны, не оказали ли они влияния на отражении данных бухгалтерского учета при исследовании сумм, подлежащих налогообложению?`,
          `В соответствии ли с Правилами ведения бухгалтерского учета отражены финансовые операции (наименование организации), связанные с формированием денежных средств в сумме … на счетах данной организации в (наименование банка) и перечислением этих средств в (наименование организации получателя), для финансирования строительства (наименование объекта)?`,
          `Какие несоответствия бухгалтерского учета и налогового законодательства способствовали сокрытию объектов налогообложения и уклонению от уплаты налогов в (наименование организации)?`,
          `Соблюдались ли в (наименование организации) требования действующих нормативных актов, регламентирующих ведение бухгалтерского   учета  при  оформлении  реализации товаров в период …?`,
          `В соответствии ли с Правилами ведения бухгалтерского учета оформлены и отражены операции, связанные с получением денежных средств (наименование организации) от (наименование банка) по строительному объекту (наименование), в соответствии с договором (номер, дата)?`,
          `Какие несоответствия были допущены в (наименование организации) при организации учета реализованной продукции, оформлении расчетных операций и контроле за денежной наличностью?`,
          `Соблюдались ли требования действующих нормативных актов, регламентирующих ведение бухгалтерского учета при реализации товаров в (наименование организации) в период …? Если нет, то, какие нормативные акты и кем не соблюдены?`,
          `В соответствии ли с Правилами ведения бухгалтерского учета отражено в бухгалтерском учете (наименование организации) поступление денежных средств в размере …, перечисленных от (наименование организации) платежным поручением(ями) (номер, дата)?`,
          `Израсходованы ли денежные средства в размере …, перечисленные (наименование организации) в период (наименование организации получателя) по целевому назначению и соответствует ли их отражение в бухгалтерском учете указанных организаций требованиям Правил ведения бухгалтерского учета?`,
          `В соответствии ли с Правилами ведения бухгалтерского учета оприходованы (наименование организации) товарно-материальные ценности, поступившие в соответствии с договором (№, дата) по первичным документам бухгалтерского учета (№, дата)?;`,
          `В соответствии ли с Правилами ведения бухгалтерского учета было осуществлено документальное оформление и отражение в бухгалтерском учете (наименование организации) перечисления суммы в размере … на счет (наименование банка и организации получателя) и счет (наименование банка и организации получателя) по (№ и дата документа)?`,
          `Соответствует ли в (наименование организации) постановка учета (наименование товарно-материальных ценностей), установленному порядку? Если имеют место отклонения, то оказало ли это влияние на образование недостачи и если да, то как именно? Обеспечивает ли действующая система документации своевременный контроль за поступлением и движением материальных ценностей и денежных средств?`,
          `Какие суммы денежных средств из представленной эксперту книги оперативного (чернового) учета (наименование магазина) не поступили в кассу (на расчетный счет)?`,
          `Являются ли представленные журналы (блокноты, распечатки данных ПК) регистрами, содержащими учетные записи? Если да, то имеют ли они тождество с данными официального учета. На что повлияют  расхождения между данными о хозяйственных операциях (указывается суть интересующих следствие операций и период их совершения), отраженных в учетных регистрах, и бухгалтерской отчетности предприятия и записями об аналогичных операциях в «черновых документах»?`,
          `Каким образом повлияет содержащаяся в протоколах допросов (указываются даты и местонахождение иных материалов дела) информация о фактическом расходе денежной наличности по кассовым ордерам (номера и даты документов) на зарегистрированной в учете задолженности подотчетных лиц (Ф.И.О.) по состоянию на  …?`,
          `Как изменится объем реализации товаров (наименование организации) с учетом записей, содержащихся в неофициальном учете (тетради № 1, №2 …) за период с … по …?`,
          `Какова сумма полученного дохода (выручки) (наименование юридического лица или Ф.И.О. гражданина-предпринимателя физического лица) за период с  … по … от ... вида деятельности?`,
          `Какова сумма затрат, связанных с полученным доходом (наименование юридического лица или Ф.И.О. гражданина-предпринимателя физического лица) за период с  … по … от ... вида деятельности?`,
          `Имела ли возможность организация (индивидуальный предприниматель) своевременно погасить кредит с учетом процентов по нему?`,
          `Какова динамика изменения кредиторской и дебиторской задолженности организации … за период с … по … (поквартально)?`,
        ]}
      />,

      <Typography
        sx={{ fontSize: '1.2rem', m: '.5rem 0' }}
        children='Первичные и сводные учетные документы:'
      />,

      <UI
        type='pointer'
        items={[
          `Учет кассовых операций.`,
          `Учет банковских операций.`,
          `Учет расчетов.`,
          `Учет труда и заработной платы.`,
          `Учет материалов.`,
          `Учет основных средств.`,
          `Учет малоценных и быстроизнашивающихся предметов.`,
          `Учет затрат на производство.`,
          `Учет готовой продукции и ее реализации.`,
          `Учет финансовых результатов.`,
          `Регистрация выписки первичного учетного документа.`,
          `Учет кассовых операций.`,
          `Учет банковских операций.`,
          `Учет расчетов.`,
          `Учет труда и заработной платы.`,
          `Учет материалов.`,
          `Учет основных средств.`,
          `Учет малоценных и быстроизнашивающихся предметов и нематериальных активов.`,
          `Учет затрат на производство.`,
          `Учет готовой продукции и ее реализация.`,
        ]}
      />,
      <Typography
        sx={{ fontSize: '1.2rem', m: '.5rem 0' }}
        children='Аналитический учет:'
      />,

      <UI
        type='pointer'
        items={[
          `По расчетным операциям.`,
          `Учет труда и заработной платы.`,
          `Учет материалов.`,
          `Учет основных средств.`,
          `Учет малоценных и быстроизнашивающихся предметов.`,
          `Учет затрат на производство.`,
          `Учет готовой продукции и ее реализация.`,
          `Учет финансовых результатов.`,
          `Синтетический учет.`,
          `Сводный учет.`,
          `Контроль.`,
          `Отчетность.`,
        ]}
      />,
      <Typography
        sx={{ fontSize: '1.2rem', m: '.5rem 0' }}
        children='Прочие материалы дела:'
      />,

      <UI
        type='pointer'
        items={[
          `Протоколы следственных действий.`,
          `Экспертные заключения различных видов судебных и криминалистических экспертиз.`,
          `Техническая документация и др.`,
        ]}
      />,
      <Typography
        sx={{ fontSize: '1.2rem', m: '.5rem 0' }}
        children='Следует отметить, что круг вопросов и документов, необходимых для разрешения поставленных задач перед бухгалтерской экспертизой, отраженный в данных методических рекомендациях, лишь только очерчен и не может включать в себя специфики учета и обстоятельств по каждому конкретному делу. Поэтому, для полноты исследования, расширения доказательственной базы по делу, необходимы предварительные консультации со специалистами, которые помогут грамотно сформулировать вопросы  и правильно подобрать необходимые документы.'
      />,
    ],
    img: '',
  },
  {
    title: 'Видеотехническая экспертиза',
    hiddenAfterCount: 4,
    collapsed: true,
    description: [
      ...[
        `Видеотехническая экспертиза проводится в целях идентификации видеоаппаратуры, а также проверки экспертным путем подлинности и достоверности видеограмм, приобщаемых к материалам уголовного дела в качестве вещественных доказательств, полученных как в ходе оперативно-розыскных мероприятий, следственных действий, так и иным путем. При этом устанавливаются конкретные видеоаппараты, на которых изготовлялась видеограмма, факты монтажа, копирования, стирания, прерывания видеограммы.`,
        `Вопросы, решаемые видеотехнической экспертизой:`,
      ].map((el) => (
        <Typography sx={{ fontSize: '1.2rem', m: '.5rem 0' }} children={el} />
      )),

      <UI
        type='pointer'
        items={[
          `В каком формате, системе цветного телевидения (ЦТВ), режиме записи выполнена видеограмма на представленной видеокассете?`,
          `Имеются ли признаки механического монтажа представленной видеозаписи?`,
          `Имеются ли признаки прерывания представленной видеограммы?`,
          `Оригиналом или копией является представленная видеограмма?`,
          `Каков порядковый номер копии представленной видеограммы?`,
          `Имеются ли признаки электронного монтажа представленной видеограммы?`,
          `Имеются ли признаки стирания части представленной видеограммы?`,
          `Все ли фрагменты, представленной видеограммы, выполнены одним и тем же видеозаписывающим аппаратом?`,
          `Имеются ли признаки несоответствия видеограммы на видеокассете №1 и видеограммы на видеокассете №2 по продолжительности записи, качеству изображения,  ракурсу съемки, порядковому номеру копии и т.д.?`,
          `На каком из представленных на исследование видеоаппаратов производилась видеозапись на данную видеокассету?`,
          `Каковы размеры объектов  внутри кадра? `,
        ]}
      />,
      ...[
        `При назначении видеотехнической экспертизы следует иметь в виду, что понятие «оригинал» может быть применено только к тем видеограммам, которые получены при непосредственной записи через объектив видеокамеры какого-либо события.           `,
        `Производятся работы по улучшению исходных видеоизображений в целях их дальнейшего использования в различных идентификационных экспертизах.`,
      ].map((el) => (
        <Typography sx={{ fontSize: '1.2rem', m: '.5rem 0' }} children={el} />
      )),
    ],
    img: '',
  },
  {
    title: 'Геммологическая экспертиза',
    hiddenAfterCount: 4,
    collapsed: true,
    description: [
      ...[
        `Геммологическая экспертиза проводится с целью установления названия  и природы камня, отнесения его к  драгоценным или поделочным камням, определения стоимости в соответствии с действующими прейскурантами и в некоторых случаях определения месторождения.`,
        `Объекты геммологической экспертизы:`,
      ].map((el) => (
        <Typography sx={{ fontSize: '1.2rem', m: '.5rem 0' }} children={el} />
      )),

      <UI
        type='pointer'
        items={[
          `бриллианты;`,
          `изумруды;`,
          `сапфиры;`,
          `александриты;`,
          `натуральный жемчуг;`,
          `полудрагоценные и поделочные камни;`,
          `искусственные и синтетические камни.`,
        ]}
      />,
      ...[`Вопросы, решаемые геммологической экспертизой:`].map((el) => (
        <Typography sx={{ fontSize: '1.2rem', m: '.5rem 0' }} children={el} />
      )),
      <UI
        type='pointer'
        items={[
          `Как называются представленные на исследова­ние камни?`,
          `Являются ли представленные на исследование камни природными?`,
          `Являются ли представленные на исследование камни драго­ценными?`,
          `Какова масса камней, представленных на исследо­вание?`,
          `Какова стоимость камней, представленных на исследование?`,
          `На каком месторождении добыты представленные на исследование камни?`,
        ]}
      />,
      ...[
        `Упаковка каждого представленного на исследование объекта проводится отдельно; она должна исключать возможность повреж­дения драгоценных камней и контакта их с металлами.`,
        `В Байкальском центре судебных экспертиз и графоанализа Геммологическую экспертизу проводят Иванова Галина Ивановна и Орловская Рита Константиновна.`,
      ].map((el) => (
        <Typography sx={{ fontSize: '1.2rem', m: '.5rem 0' }} children={el} />
      )),
    ],
    img: '',
  },
  {
    title: 'Графологическое исследование',
    hiddenAfterCount: 4,
    collapsed: true,
    description: [
      ...[
        `Графология - это изучение проявлений в почерке индивидуальных особенностей человеческой личности. Позволяет по почерку лиц определить: основные черты характера данного человека; степень проявления тех или иных личностных качеств, в частности, способности принимать решения, самоконтроля, устойчивости, оригинальности и т.п.; профессиональные характеристики лица, в частности, пригодность его для занятия тех или иных должностей и выполнения того или иного круга задач.`,
        `Проводится диагностика личности в целом и по отдельным специализациям:`,
      ].map((el) => (
        <Typography sx={{ fontSize: '1.2rem', m: '.5rem 0' }} children={el} />
      )),

      <UI
        type='pointer'
        items={[
          `Графология в сфере занятости и бизнеса. Комплексная безопасность бизнеса - исследование личности с применением методов графологии и полиграфа.`,
          `Cоциальная графология: профориентация и подбор кандидатов на различные должности; предотвращение утечки информации; определение неэффективности сотрудников; разрешение внутренних конфликтов.`,
          `Графология в сфере образования: помощь старшеклассникам в выборе профессии, высшего (средне-специального) учебного заведения, связанного с профессиональной направленностью.`,
          `Судебная графология: идентификация или сравнение документов (почерков, подписей), для судебного рассмотрения разногласий по поводу подписи, либо завещания, почерка и цифр, фигурируемых на чеках и т.д.`,
          `Медицинская графология: исследование связей между различными заболеваниями и признаками почерка.`,
        ]}
      />,
    ],
    img: '',
  },
  {
    title: 'Землеустроительная экспертиза',
    hiddenAfterCount: 4,
    collapsed: true,
    description: [
      ...[
        `Землеустроительная экспертиза - комплекс действий, производимых в установленной законом форме для определения границ локального земельного участка.`,
        `Эта экспертиза осуществляется специалистами в области землеустройства, почвоведения и других дисциплин, дающими заключения по вопросам, возникающим в процессе гражданского и уголовного судопроизводства, производства по делам об административных правонарушениях.`,
        `Объектом землеустроительной экспертизы является локальный земельный участок, в пределах которого произошло правонарушение.`,
        `Землеустроительная экспертиза разрешает следующие группы вопросов: `,
      ].map((el) => (
        <Typography sx={{ fontSize: '1.2rem', m: '.5rem 0' }} children={el} />
      )),

      <UI
        type='pointer'
        items={[
          `Вопросы, относительно определения площади земельного участка, в том числе его частей.`,
          `Вопросы, относительно определения фактических границ земельного участка.`,
          `Вопросы, относительно определения порядка пользования общего неделимого земельного участка между двумя и более собственниками.`,
          `Вопросы, относительно определения границ земельного участка в натуре по первоначальным землеотводным документам, в том числе по сведениям, содержащимся в государственном кадастре недвижимости.`,
          `Вопросы, относительно определения месторасположения объектов недвижимости на земельном участке и элементов благоустройства.`,
          `Вопросы, относительно оценки по делам, связанным с оспариванием кадастровой стоимости.`,
          `Исследование специалистами в области геодезии и кадастра объектов недвижимости материалов землеустройства, правоустанавливающих и правоудостоверяющих документов на земельный участок с фактическим землепользованием.`,
        ]}
      />,
    ],
    img: '',
  },
  {
    title: 'Исследование китайского иероглифического письма',
    hiddenAfterCount: 4,
    collapsed: true,
    description: [
      ...[
        `Китайская иероглифическая письменность – исключительное явление среди современных письменностей. Знак китайского письма представляет собой сложную графическую фигуру. Необычность китайского письма всегда вызывала любопытство как ученых специалистов, так и многочисленных любителей. Иероглифическое письмо отличается от алфавитного не только формой или степенью сложности отдельных знаков. Отличия проявляются во всех свойствах знаков этих двух видов письменности.`,
        `Производство исследований подобного рода осуществляется с привлечением не только квалифицированного эксперта-почерковеда, но и специалиста-переводчика, что обеспечивает объективность, правильность и обоснованность выводов наших экспертных заключений.`,
        `Данный вид исследования решает следующие подгруппы вопросов:`,
      ].map((el) => (
        <Typography sx={{ fontSize: '1.2rem', m: '.5rem 0' }} children={el} />
      )),

      <UI
        type='pointer'
        items={[
          `Вопросы, решаемые при установлении исполнителя китайского иероглифического письма.`,
          `Вопросы, решаемые при установлении относительных данных об исполнителе китайского иероглифического текста.`,
        ]}
      />,
    ],
    img: '',
  },
  {
    title: 'Компьютерно-техническая экспертиза',
    hiddenAfterCount: 4,
    collapsed: true,
    description: [
      ...[
        `Компьютерно-техническая экспертиза (КТЭ) относится к классу инженерно-технических экспертиз, проводится в целях: определения статуса объекта как компьютерного средства, выявления и изучения  его роли  в расследуемом преступлении, а также получения доступа к информации на носителях данных с последующим  всесторонним её исследованием.`,
        `Чаще всего при производстве КТЭ решаются следующие задачи:`,
      ].map((el) => (
        <Typography sx={{ fontSize: '1.2rem', m: '.5rem 0' }} children={el} />
      )),

      <UI
        type='pointer'
        items={[
          `поиск на жестком диске представленного ПК или иных носителях данных информации определенного содержания (графических файлов с изображениями денежных знаков, которые могли бы использоваться при производстве поддельных купюр, текстовых файлов, содержащих образы поддельных документов и т.д.);`,
          `поиск на представленных магнитных или иных носителях информации программ, заведомо приводящих к несанкционированному уничтожению, блокированию, модификации либо копированию информации, нарушению работы ЭВМ, локальной вычислительной сети ЭВМ, принципов и результатов их действия;`,
          `задачи, связанные с использованием сети ИНТЕРНЕТ для совершения противоправных действий.`,
        ]}
      />,
      ...[`На нынешнем этапе типовыми объектами КТЭ являются:`].map((el) => (
        <Typography sx={{ fontSize: '1.2rem', m: '.5rem 0' }} children={el} />
      )),

      <UI
        type='pointer'
        items={[
          `системные блоки персональных компьютеров, жесткие диски, флоппи-диски (5.25’’ и 3.5’’), CD-ROMы, магнитооптические диски и другие накопители данных;`,
          `серверы (на платформе -процессов, либо совместимых с ними);`,
          `массивы;`,
          `системные и прикладные программные средства;`,
          `файлы, подготовленные с использованием различных программных средств.`,
        ]}
      />,
      ...[`Вопросы, решаемые компьютерно - технической экспертизой:`].map(
        (el) => (
          <Typography sx={{ fontSize: '1.2rem', m: '.5rem 0' }} children={el} />
        )
      ),

      <UI
        type='pointer'
        items={[
          `Относится  ли представленный объект к объектам КТЭ?`,
          `Является ли объект экспертизы  компьютерной системой либо представляет какую-либо его компоненту (аппаратную, программную, информационную)?`,
          `Каковы тип (марка, модель), конфигурация и общие технические характеристики представленной компьютерной системы (либо ее части)?`,
          `Решаются ли с помощью представленной компьютерной системы определённые (указываются конкретно какие) функциональные (потребительские) задачи?`,
          `Находится ли компьютерная система в рабочем состоянии?`,
          `Имеются ли какие-либо неисправности в ее работе?`,
          `Имеются ли признаки (указывается интересуемый  перечень конкретных признаков) нарушения правил эксплуатации компьютерной системы?`,
          `Реализована ли в компьютерной системе какая-либо система защиты доступа к информации?`,
          `Каковы возможности по её преодолению?`,
          `Какие носители данных имеются в представленной компьютерной системе?`,
          `Какой вид (тип, модель, марку) и какие параметры имеет представленный носитель данных?`,
          `Какое устройство предназначено для работы с представленным носителем данных?`,
          `Имеется ли в составе представленной компьютерной системы устройство, предназначенное для работы (чтение, запись) с указанным носителем данных?`,
          `Какую общую характеристику и функциональное предназначение имеет программное обеспечение представленного объекта?`,
          `Каковы реквизиты разработчика, правообладателя представленного программного средства?`,
          `Имеет ли программное средство признаки (указывается интересуемый  перечень конкретных признаков) контрафактности?`,
          `Имеется ли на носителях данных программное обеспечение для решения конкретной (потребительской) задачи?`,
          `Каково функциональное предназначение представленной прикладной программы?`,
          `Имеются ли программы с признаками (указывается интересуемый  перечень конкретных признаков) вредоносности?`,
          `Какая информация, имеющая отношение к обстоятельствам дела (указывается интересуемый  перечень конкретных данных, либо ключевых слов), содержится  на носителе данных?`,
          `Каков вид ее представления (явный, скрытый, удаленный, архивный)?`,
          `Имеется ли на носителе данных информация, аутентичная по содержанию представленным образцам?`,
          `Каков вид ее представления (явный, скрытый, удаленный, архивный)?`,
          `К какому формату относятся выявленные данные (текстовые документы, графические файлы, базы данных и т.д.) и с помощью каких программных средств они могут обрабатываться?`,
          `Имеются ли в компьютерной системе признаки (указывается интересуемый  перечень конкретных признаков) неправомерного доступа к данным?`,
          `Какие сведения о собственнике (пользователе) компьютерной системы (в т.ч. имена, пароли, права доступа и пр.) имеются на носителях  данных?`,
          `Имеются ли признаки функционирования данного компьютерного средства в составе локальной вычислительной сети?`,
          `Каково содержание установленных сетевых компонент?`,
          `Имеются ли признаки работы представленного компьютерного средства в сети Интернет?`,
          `Каково содержание установок удаленного доступа и протоколов соединений?`,
        ]}
      />,
    ],
    img: '',
  },
  {
    title: 'Лингвистическая экспертиза ',
    hiddenAfterCount: 4,
    collapsed: true,
    description: [
      ...[
        `Необходимость производства лингвистических экспертиз в гражданском, арбитражном, уголовном процессе, административном производстве связана с тем, что в современных социально-экономических условиях множатся судебные иски по защите чести, достоинства и деловой репутации, авторских и других смежных прав. Без использования специальных лингвистических познаний, как правило, невозможно разрешение дел о плагиате, производстве и распространении контрафактной книгопечатной продукции. Весьма востребованы экспертизы этого рода при расследовании и судебном рассмотрении дел об оскорблениях и клевете, возбуждении национальной, расовой и религиозной вражды, призывах к свержению конституционного строя.`,
        `Предметом судебной лингвистической экспертизы являются факты и обстоятельства, устанавливаемые на основе исследования закономерностей существования и функционирования естественного или искусственного языка.`,
        `Объектами лингвистических экспертиз являются речевые проявления в форме письменного текста или устного высказывания. Результаты лингвистического анализа содержательно-смысловой и формальной стороны речевого произведения являются основным источником доказательственной информации, как при разрешении гражданско-правовых споров, так и при расследовании и судебном рассмотрении уголовных дел, дел об административных правонарушениях.`,
        `Вопросы, выносимые на разрешение лингвистических экспертиз письменных и устных текстов:`,
        `Вопросы общего характера:`,
      ].map((el) => (
        <Typography sx={{ fontSize: '1.2rem', m: '.5rem 0' }} children={el} />
      )),

      <UI
        type='pointer'
        items={[
          `Является ли данный язык родным для автора текста, если нет, то какой язык предположительно является для него родным?`,
          `Носителем какого наречия (диалекта, говора) является автор текста?`,
          `Является ли творчески-самобытным название, коммерческое имя, товарный знак, слоган, доменное имя?`,
          `Содержатся ли в данной статье негативные сведения об определенном лице (лицах), его деятельности и о его личных деловых и моральных качествах? В каких конкретно высказываниях содержится негативная информация? Если в вышеуказанных фразах имеются негативные сведения о нем, то в какой форме они выражены: утверждения, предположения, вопроса?`,
          `Если в представленных материалах содержится негативная информация об указанных лицах, то воспринимается ли эта информация как чернящая доброе имя, задевающая честь и достоинство этого лица, других руководителей данной организации, как умаляющая деловую репутацию указанных лиц?`,
          `Подтверждает лингвостилистический анализ выделенных фраз, что в них содержатся сведения в форме утверждений о нарушении г-ном (ФИО) действующего законодательства, моральных норм и принципов, а также утверждения, позорящие его производственно-хозяйственную и общественную деятельность, умаляющие его деловую и общественную репутацию?`,
          `Содержит ли данное предложение (цитата) сведения, унижающие честь и умаляющие достоинство, задевающие, чернящие деловую репутацию физического или юридического лица?`,
          `В каких фрагментах данной статьи (радиопередачи, видеосюжета) содержится информация о деловой репутации данного юридического лица (общественной организации, организации, фирмы)?`,
          `Если сведения, изложенные в статье (радиопередаче, видеосюжете), не соответствуют действительности, то можно ли их квалифицировать как чернящие деловую репутацию истца (гражданина, организации)?`,
          `Являются ли сведения, изложенные в данном абзаце (цитата), утверждениями о фактах (если да, то каких) или мнением автора (журналиста, редакции) статьи (радиопередачи, видеосюжета)?`,
        ]}
      />,
      ...[
        `Вопросы, относящиеся исключительно к судебной экспертизе письменных текстов:`,
      ].map((el) => (
        <Typography sx={{ fontSize: '1.2rem', m: '.5rem 0' }} children={el} />
      )),

      <UI
        type='pointer'
        items={[
          `Составлен ли данный документ лицом, обладающим навыками научного (публицистического, делового и проч.) стиля письменной речи?`,
          `В каком значении употреблено слово (цитата) в контексте данного абзаца или предложения (цитата) в статье, опубликованной в печатном издании (название)?`,
          `Каковы наиболее существенные и значимые жанровые, психолингвистические и стилистические особенности текста публикации (название)?`,
          `Какова композиционная структура текста статей (статьи), какие художественные приемы использует автор и как они характеризуют героев публикации?`,
        ]}
      />,
    ],
    img: '',
  },
  {
    title: 'Пожарно-техническая экспертиза',
    hiddenAfterCount: 4,
    collapsed: true,
    description: [
      ...[
        `Пожарно-техническая экспертиза (ПТЭ) проводится в целях установления места возникновения пожара, технической причины и обстоятельств его возникновения и развития.`,
        `Основными задачами пожарно-технической экспертизы являются:`,
      ].map((el) => (
        <Typography sx={{ fontSize: '1.2rem', m: '.5rem 0' }} children={el} />
      )),

      <UI
        type='pointer'
        items={[
          `определение места возникновения первоначального горения (очага пожара);`,
          `характеризация динамики пожара во времени и пространстве;`,
          `определение вида теплового источника и установление механизма первоначального горения;`,
          `выявление условий и обстоятельств, способствующих развитию пожара.`,
          `выявление обстоятельств, связанных с нарушением правил пожарной безопасности и действий (бездействий) участников тушения пожара и проведения аварийно-спасательных работ, способствовавших получению вреда здоровью человека (смерть человека) или иных тяжких последствий.`,
        ]}
      />,
      <Typography
        sx={{ fontSize: '1.2rem', m: '.5rem 0' }}
        children='А. Установление очага пожара:'
      />,
      <UI
        type='pointer'
        items={[
          `Где находился очаг пожара?`,
          `Является ли данный участок местности (часть помещения, обстановки) местом возникновения пожара (очагом пожара)?`,
          `Сколько имелось очагов пожара, какова их взаимосвязь, последовательность возникновения?`,
        ]}
      />,
      <Typography
        sx={{ fontSize: '1.2rem', m: '.5rem 0' }}
        children='Б. Установление особенностей развития пожара:'
      />,
      <UI
        type='pointer'
        items={[
          `Каковы пути распространения огня?`,
          `Какие условия способствовали развитию пожара?`,
          `Чем объясняются отмеченные на пожаре вспышки, звуковые эффекты, интенсивное дымовыделение и другие явления?`,
          `Чем объясняется наибольшая степень термического воздействия (выгорание, деформация, разрушение) конструктивных элементов, предметов вещной обстановки, материалов и т.п. в данном месте?`,
        ]}
      />,
      <Typography
        sx={{ fontSize: '1.2rem', m: '.5rem 0' }}
        children='B. Установление продолжительности горения:'
      />,
      <UI
        type='pointer'
        items={[
          `В течение какого времени происходило выгорание, переугливание данных конструкций, предметов и т.п.?`,
          `За какой промежуток времени воздействие представленных источников зажигания может вызвать воспламенение данных веществ, материалов, конструкций, предметов?`,
          `В течение какого времени, возможно, разрушение данной конструкции, устройства, материала и т.п. в условиях конкретного пожара?`,
        ]}
      />,
      <Typography
        sx={{ fontSize: '1.2rem', m: '.5rem 0' }}
        children='Г. Установление технической причины (механизма) возникновения пожара:'
      />,
      <UI
        type='pointer'
        items={[
          `Какова непосредственная техническая причина (тепловой источник) возникновения пожара?`,
          `Возник ли пожар в результате самовозгорания данного вещества, материала под действием источников зажигания указанного вида в следующих условиях?`,
          `Возник ли пожар в результате самовозгорания данных веществ, материалов в следующих условиях?`,
          `Имеются ли на представленных объектах признаки аварийных электрических процессов: короткого замыкания, токовой перегрузки, других аварийных явлений электрической природы? Каковы причины их возникновения? Имеются ли на токоведущих элементах электросети (электроустановки) оплавления металла, если да, то какова природа их образования (электрический процесс или внешнее термическое воздействие)? Каков механизм возникновения и развития аварийного режима в данной электроустановке (электросети)? Соответствует ли устройство электросети (подбор элементов и условия монтажа) требованиям Правил устройства электроустановок (ПУЭ)?`,
          `Имеются ли на представленных объектах следы легковоспламеняющихся жидкостей (ЛВЖ) или горюче-смазочных материалов (ГСМ)? Если да, то каких? Имеются ли на окружающих предметах, зафиксированных на фотоснимках (рисунках) признаки горения легковоспламеняющихся и горючих жидкостей? Возможно ли образование в помещении указанных размеров пожаро- и взрывоопасных концентраций газа или паров горючих жидкостей при определенных условиях (указать каких) истечения газа или испарения жидкости в течение определенного промежутка времени?`,
          `Относятся ли вещества и материалы (указать какие) к группе самовозгорающихся или самовоспламеняющихся? Возможно ли химическое, микробиологическое или тепловое самовозгорание этих веществ в определенных (указать каких) условиях?`,
          `Возможно ли возгорание (воспламенение, тление) при контакте малокалорийного источника зажигания (расплавленные частицы металла определенной природы происхождения, горящие частицы твердых веществ, тлеющие табачные изделия и т.п.) с конкретным горючим материалом или веществом (указать вид материала, количество и особенности расположения)?`,
        ]}
      />,
      <Typography
        sx={{ fontSize: '1.2rem', m: '.5rem 0' }}
        children='Д. Техническая оценка средств поджога:'
      />,
      <UI
        type='pointer'
        items={[
          `Свидетельствует ли характер обгорания предметов, наблюдаемые очевидцами особенности развития горения или признаки (указать какие) о создании условий, способствующих интенсификации распространения горения?`,
          `Может данное устройство быть применено в качестве поджигающего, является ли оно самодельным и каков принцип его работы?`,
          `Обеспечит ли данное устройство зажигание определенного вещества, предмета в следующих условиях (указать конкретное вещество и условия)?`,
        ]}
      />,
      <Typography
        sx={{ fontSize: '1.2rem', m: '.5rem 0' }}
        children='Е. Установление обстоятельств, связанных с нарушением правил пожарной безопасности, способствовавших получению вреда здоровью человека (и или смерть человека) при пожаре:'
      />,
      <UI
        type='pointer'
        items={[
          `Имеются на данном объекте несоответствия требованиям Правил пожарной безопасности, находящиеся в причинно-следственной связи с возникновением пожара или способствовавшие быстрому распространению горения, если да, то в чем они состоят?`,
          `Имеются на данном объекте несоответствия требованиям Строительных норм и правил, находящиеся в причинно-следственной связи с возникновением пожара или способствовавшие быстрому распространению горения, если да, то в чем они состоят?`,
          `Соответствуют ли эвакуационные пути и выходы (в том числе наружные и незадымляемые лестницы) объекта пожара требованиям пожарной безопасности? В чем конкретно заключаются несоответствия эвакуационных путей и выходов нормативным требованиям, могли ли данные отступления (нарушения) от требований пожарной безопасности находится в причинно-следственной связи с получением травм или гибелью человека (людей) при пожаре?`,
          `Подлежал ли объект пожара защите автоматическими установками пожаротушения и автоматической сигнализации?`,
          `Подлежал ли объект пожара оборудованию системами оповещения и управления эвакуацией людей, а также системами противодымной защиты?`,
          `Выполнили ли данные автоматические системы защиты (установки пожаротушения и автоматической сигнализации, системы противодымной защиты) объекта пожара свою задачу (в том числе, сработали ли они)?`,
          `Находится ли отсутствие системы защиты автоматическими установками пожаротушения и автоматической сигнализации на объекте пожара в причинно-следственной связи с получением травм или гибелью человека (людей) при пожаре? Находится ли отсутствие системы оповещения и управления эвакуацией людей о пожаре на объекте пожара в причинно-следственной связи с получением травм или гибелью человека (людей) при пожаре?`,
          `Соответствовали ли обрушившиеся конструкции здания объекта пожара требованиям пожарной безопасности по степени огнестойкости?`,
        ]}
      />,
      <Typography
        sx={{ fontSize: '1.2rem', m: '.5rem 0' }}
        children='Ж. Установление обстоятельств, связанных с нарушением правил пожарной безопасности, способствовавших наступлению иных тяжких последствий при пожаре:'
      />,
      <UI
        type='pointer'
        items={[
          `Соответствовало ли сгоревшее здание по уровню противопожарной защиты требованиям действующих Правил пожарной безопасности, СНиП и других нормативных документов. Если нет, то как отразилось это несоответствие на динамике развития пожара и тяжести его последствий?`,
          `Невыполнение каких пунктов предписания Государственного пожарного надзора обследования здания способствовало быстрому распространению горения и блокированию путей эвакуации людей из здания?`,
          `Соответствуют ли объемно-планировочные и конструктивные решения объекта пожара (в том числе степень огнестойкости, геометрические параметры, размещение помещений, конструктивные решения, противопожарные преграды и др.) требованиям пожарной безопасности?`,
          `Находятся ли отступления (нарушения) от требований пожарной безопасности объемно-планировочных и конструктивных решений объекта пожара в причинно-следственной связи с быстрым распространением горения и наступлением тяжких последствий?`,
          `Находятся ли согласованные в установленном порядке отступления от требований пожарной безопасности объемно-планировочных и конструктивных решений объекта пожара в причинно-следственной связи с быстрым распространением горения и наступлением тяжких последствий?`,
          `Подлежал ли объект пожара защите автоматическими установками пожаротушения и автоматической сигнализации?`,
          `Выполнила ли автоматическая система защиты (установки пожаротушения и автоматической сигнализации) объекта пожара свою задачу (сработала ли она?) Находится ли отсутствие системы защиты автоматическими установками пожаротушения и автоматической сигнализации на объекте пожара в причинно-следственной связи с наступлением тяжких последствий при пожаре?`,
          `Соответствовала ли категория помещений (наружных установок) объекта пожара по взрыво-, пожароопасности требования пожарной безопасности? Находится ли несоответствие категории помещений (наружных установок) объекта пожара по взрыво-, пожароопасности требования пожарной безопасности в причинно-следственной связи с наступлением тяжких последствий при пожаре?`,
        ]}
      />,
      <Typography
        sx={{ fontSize: '1.2rem', m: '.5rem 0' }}
        children='З. Установление обстоятельств, связанных с нарушением действующего Законодательства и нормативных актов участников тушения пожара и проведения аварийно-спасательных работ:'
      />,
      <UI
        type='pointer'
        items={[
          `Какие пункты правил пожарной безопасности нарушены в период от обнаружения пожара до прибытия первых пожарных подразделений, если нарушены, то находятся ли они в причинной связи с последствиями пожара?`,
          `Какие пункты Устава противопожарной службы нарушены в период от поступления сообщения до ликвидации пожара, если нарушены, то находятся ли они в причинной связи с последствиями пожара?`,
          `Какие пункты нормативных документов нарушены должностными лицами в период от поступления сообщения до ликвидации пожара по управлению силами и средствами на пожаре, если нарушены, то находятся ли они в причинной связи с последствиями пожара?`,
        ]}
      />,

      <Typography
        sx={{ fontSize: '1.2rem', m: '.5rem 0' }}
        children='Для производства пожарно-технической экспертизы представляются следующие исходные данные и объекты: устройства для зажигания веществ и материалов;'
      />,
      <UI
        type='pointer'
        items={[
          `устройства для зажигания веществ и материалов;`,
          `предметы и их остатки со следами легковоспламеняющихся и горючих жидкостей, других химических веществ;`,
          `отрезки электрических проводов и кабелей с оплавлениями жил (при прокладке в трубах – вместе с трубами) с указанием места их изъятия;`,
          `устройства электрозащиты (плавкие предохранители, автоматические выключатели), электрокоммутирующие устройства с признаками аварийных режимов;`,
          `электронагревательные приборы вместе со шнурами;`,
          `остатки поврежденных электроламп накаливания и люминесцентных светильников с признаками аварийных режимов;`,
          `предметы с локализованными признаками термического воздействия, несущие информацию об их происхождении от определенного теплового источника;`,
          `образцы горючих материалов и веществ для сравнительного исследования и проведения экспериментов.`,
        ]}
      />,
      <Typography
        sx={{ fontSize: '1.2rem', m: '.5rem 0' }}
        children='Помимо объектов, для проведения исследований эксперту необходимы следующие основные исходные данные и документы:'
      />,
      <UI
        type='pointer'
        items={[
          `дата, время суток и место обнаружения пожара; акт о пожаре, описание пожара;`,
          `вид объекта, его исходные строительные и планировочные характеристики с пространственной ориентацией по сторонам света; данные о материалах и огнестойкости конструкций;`,
          `виды, количество, места и способы размещения горючих материалов на объекте;`,
          `схемы наружного (от трансформаторной подстанции) и внутреннего электроснабжения объекта; вид, количество и места размещения осветительного и силового электрооборудования; марка и длина участков проводов и кабелей;`,
          `характеристики систем отопления, автоматического обнаружения и тушения пожара и т.п.;`,
          `сведения о событиях и обстоятельствах, предшествовавших и сопровождавших пожар, которые могли иметь с ним связь;`,
          `условия обнаружения пожара и признаки, по которым он был обнаружен; особенности развития пожара и признаки, по которым он был обнаружен; особенности развития пожара, трудности при его тушении, общая продолжительность и основные последствия;`,
          `протокол следственного осмотра места пожара с фототаблицами, схемами, видеоматериалами, перечнем изъятых вещественных доказательств с указанием на схеме мест их изъятия;`,
          `строительная документация на объект (из архивов или БТИ); различные фото и видеоматериалы, отснятые как в период предшествовавший пожару, так и в ходе развития и тушения пожара.`,
        ]}
      />,
    ],
    img: '',
  },
  {
    title: 'Почерковедческая экспертиза',
    hiddenAfterCount: 4,
    collapsed: true,
    description: [
      ...[
        `Судебное почерковедение – один из видов криминалистической идентификации личности. Идентификационные задачи почерковедения состоят в установлении факта выполнения конкретным лицом данного текста, различных текстов или фрагментов текстов, либо исполнителя подписи. Целями диагностических исследований рукописей являются: установление факта письма в необычных условиях либо при необычном состоянии писавшего, а также определение пола, возраста и других сведений об исполнителе текста.`,
        `Основные идентификационные вопросы, решаемые при установлении исполнителя рукописных текстов:`,
      ].map((el) => (
        <Typography sx={{ fontSize: '1.2rem', m: '.5rem 0' }} children={el} />
      )),

      <UI
        type='pointer'
        items={[
          `Кем из числа проверяемых лиц выполнен рукописный текст (фрагмент текста, запись, подпись и т.п.) в исследуемом документе?`,
          `Одним или разными лицами выполнены рукописные тексты (фрагменты текста, записи, подписи и т.п.) в исследуемых документах?`,
        ]}
      />,
      <Typography
        sx={{ fontSize: '1.2rem', m: '.5rem 0' }}
        children='Основные диагностические вопросы, решаемые при установлении данных об исполнителе и обстоятельствах выполнения рукописного текста:'
      />,
      <UI
        type='pointer'
        items={[
          `Лицом, какого пола выполнен рукописный текст?`,
          `К какой возрастной группе относится исполнитель рукопис­ного текста исследуемого документа?`,
          `Не выполнена ли рукопись в каком-то необычном психофизическом состоянии либо в необычных условиях?`,
          `В какой позе писавшего (стоя, сидя, лежа) выполнена исследуемая рукопись?`,
          `Не выполнен ли текст (подпись, запись) с подражанием почерку конкретного лица?`,
          `Не выполнен ли рукописный текст (подпись, запись) намеренно измененным почерком?`,
          `Не выполнен ли рукописный текст (подпись) непривычно пишущей рукой?`,
          `Пригодна ли подпись (запись, надпись) для идентификации личности?`,
        ]}
      />,
      ...[
        `При назначении почерковедческой экспертизы исследуемый до­кумент следует предоставлять эксперту в оригинале. Лишь в осо­бых случаях (например, надпись на стене, мебели, отсутствие ори­гинала) допустимо предоставление фотоснимка или ксерокопии текста (подписи).`,
        `В качестве сравнительного материала в распоряжение экспер­та необходимо предоставить образцы письма предполагаемого ис­полнителя. Различают свободные, условно - свободные и экспери­ментальные образцы.`,
        `Свободные образцы - это рукописные тексты и подписи, вы­полненные вне связи с делом, по которому проводится экспертиза. Свободные образцы должны, по возможности, соответствовать ис­следуемой рукописи по содержанию, целевому назначению, време­ни исполнения; они должны быть выполнены на том же языке, однотипным пишущим прибором и на таком же материале.`,
        `Условно - свободные образцы  -  это рукописи и подписи, выпол­ненные в связи с расследуемым событием после возбуждения уго­ловного дела (объяснения, протоколы допросов, жалобы по данно­му делу или выполненные в других документах в это время).`,
        `Экспериментальные образцы - это рукописи и подписи, вы­полненные предполагаемым исполнителем по предложению следо­вателя в связи с производством экспертизы. Их выполняют под диктовку либо предлагают лицу самостоятельно написать какой-либо документ (автобиографию, объяснение, собственноручные по­казания по делу).      `,
        `Если исследуемый текст выполнен с переменой пишущей руки или буквами печатной формы, или в непривычных условиях, то пи­шущему дополнительно предлагают исполнить образцы соответст­вующим образом.`,
        `Экспериментальные образцы подписей следует отбирать в не­сколько приемов на разных листах бумаги с соблюдением условий, близких к условиям выполнения спорной подписи (бланк, площадь для размещения подписи, пишущий прибор). Если спорная под­пись выполнена от имени определенного лица, то на экспертизу направляются образцы письма в виде рукописного текста и под­писей данного лица, а также подозреваемого. Последние включа­ют образцы собственных подписей подозреваемого, эксперимен­тальные образцы в виде фамилии лица, от имени которого выпол­нена спорная подпись, а также свободные и экспериментальные образцы рукописей подозреваемого.`,
        `Для производства экспертизы необходимо направить: свобод­ные образцы рукописного текста на 5-10 листах; эксперимен­тальные образцы рукописного текста на 5-12 листах; 8-10 свободных и 15-20 экспериментальных образцов подписи.`,
      ].map((el) => (
        <Typography sx={{ fontSize: '1.2rem', m: '.5rem 0' }} children={el} />
      )),
    ],
    img: '',
  },
  {
    title: 'Психологическая экспертиза',
    hiddenAfterCount: 4,
    collapsed: true,
    description: [
      ...[
        `Психологическая экспертиза назначается в случаях, когда при выяснении важных для дела обстоятельств требуются специальные психологические знания. Судебно-психологическая экспертиза направлена на исследование непатологических явлений психики и поэтому проводится преимущественно в отношении психически здоровых людей. Она осуществляется амбулаторно комиссией экспертов или одним экспертом (если создание экспертной комиссии невозможно или нецелесообразно).`,
        `Назначение психологической экспертизы по уголовному делу целесообразно в тех случаях, когда психическое здоровье направляемых на экспертизу лиц не вызывает сомнения у представителей следственных органов или подтверждено заключением психиатрической экспертизы. Поэтому психологическая экспертиза не должна предшествовать психиатрической или проводиться параллельно с ней в рамках комплексной психолого-психиатрической экспертизы.`,
        `Основные задачи психологической экспертизы:`,
      ].map((el) => (
        <Typography sx={{ fontSize: '1.2rem', m: '.5rem 0' }} children={el} />
      )),

      <UI
        type='pointer'
        items={[
          `Установление способности психически здоровых подэкспертных лиц, свидетелей и потерпевших воспринимать имеющие значение для дела обстоятельства и давать о них правильные показания.`,
          `Установление способности психически здоровых потерпевших по делам об изнасиловании правильно понимать характер и значение совершаемых с ними действий и оказывать сопротивление виновному.`,
          `Установление способности отстающих в психическом развитии несовершеннолетних обвиняемых полностью сознавать значение своих действий и определение степени способности руководить своими действиями.`,
          `Установление наличия или отсутствия у обвиняемого в момент совершения противоправных действий состояния физиологического аффекта или иных эмоциональных состояний, способных существенно повлиять на его сознание и деятельность.`,
          `Установление возможности возникновения у субъекта различных психических состояний или выявление индивидуально-психологических особенностей, делающих невозможным или затрудняющих выполнение профессиональных функций (в авиации, автомобильном и железнодорожном транспорте, в работе оператора автоматизированных систем на производстве и т.п.).`,
          `Установление наличия или отсутствия у лица в период, предшествовавший смерти, психического состояния, предрасполагавшего к самоубийству. Это требует проведения посмертной судебно-психологической экспертизы, которая может оказаться полезной, если следственные органы располагают сведениями о совершении определенными людьми действий, провоцирующих самоубийство (доведение до самоубийства), а также при возникновении предположения относительно инсценировки самоубийства.`,
          `Установление у испытуемого лица индивидуально-психологических особенностей, которые могли способствовать совершению конкретных противоправных деяний.`,
        ]}
      />,
      ...[
        `Примерный перечень вопросов, выносимых на разрешение судебно-психологической экспертизы:`,
        `I. При установлении способности воспринимать обстоятельства и давать о них правильные показания:`,
      ].map((el) => (
        <Typography sx={{ fontSize: '1.2rem', m: '.5rem 0' }} children={el} />
      )),
      <UI
        type='pointer'
        items={[
          `Учитывая индивидуальные и возрастные особенности обследуемого и конкретные условия, в которых происходило событие (указать какое), мог ли он правильно воспринимать важные для дела обстоятельства (указать - какие именно)?`,
          `Учитывая психическое состояние обследуемого в момент конкретного события, мог ли он правильно воспринимать важные для дела обстоятельства (указать - какие именно)?`,
          `Обладает ли испытуемый абсолютной чувствительностью зрительного, слухового (или иного) анализатора, достаточной для восприятия раздражителя (указать - какого)?`,
          `Имеются ли у испытуемого признаки повышенной внушаемости?`,
          `Имеются ли у испытуемого признаки повышенной склонности к фантазированию?`,
          `Имеются ли у испытуемого признаки эйдетической памяти?`,
        ]}
      />,
      ...[
        `II. Установление способности изнасилованных правильно понимать совершаемые с ними действия и оказывать сопротивление виновному:`,
      ].map((el) => (
        <Typography sx={{ fontSize: '1.2rem', m: '.5rem 0' }} children={el} />
      )),
      <UI
        type='pointer'
        items={[
          `Имеются ли у испытуемой индивидуально-психологические особенности, например, не связанное с психическими заболеваниями отставание в психическом развитии, характерологические черты, свойства эмоционально-волевой сферы, которые могли существенно повлиять на ее поведение в исследуемой ситуации?`,
          `Учитывая индивидуально-психологические особенности испытуемой и содержание исследуемой ситуации, могла ли испытуемая понимать характер и значение совершаемых с нею действий?`,
          `Учитывая индивидуально-психологические особенности испытуемой и содержание исследуемой ситуации, могла ли испытуемая оказать сопротивление?`,
        ]}
      />,
      <Typography
        sx={{ fontSize: '1.2rem', m: '.5rem 0' }}
        children='III. Установление способности несовершеннолетних обвиняемых сознавать свои действия и руководить ими:'
      />,
      <UI
        type='pointer'
        items={[
          `Имеются ли у несовершеннолетнего признаки не связанного с психическими заболеваниями отставания в психическом развитии, и если да, то в чем конкретно они выражаются?`,
          `Учитывая состояние психического развития несовершеннолетнего, мог ли он полностью сознавать значение своих действий?`,
          `Учитывая состояние психического развития несовершеннолетнего, в какой мере он мог руководить своими действиями?`,
        ]}
      />,
      <Typography
        sx={{ fontSize: '1.2rem', m: '.5rem 0' }}
        children='IV. Установление у обвиняемого в момент происшествия состояния, способного влиять на его сознание и деятельность:'
      />,
      <UI
        type='pointer'
        items={[
          `Находился ли обследуемый в момент совершения преступления в состоянии физиологического аффекта?`,
          `Находился ли обследуемый в момент совершения преступления в эмоциональном состоянии (стресс, фрустрация, растерянность), которое могло существенно повлиять на его сознание и деятельность?`,
        ]}
      />,
      <Typography
        sx={{ fontSize: '1.2rem', m: '.5rem 0' }}
        children='V. Установление психических особенностей, исключающих нормальное выполнение профессиональных функций:'
      />,
      <UI
        type='pointer'
        items={[
          `Находился ли обследуемый в момент инкриминируемых ему деяний в психическом состоянии, которое могло оказать существенное влияние на качество выполнения профессиональных функций?`,
          `Мог ли обследуемый, учитывая его индивидуально-психологические особенности, правильно оценить ситуацию (или ее отдельные компоненты), принять правильное решение и реализовать его?`,
          `Соответствует ли психологический уровень сформированное у обследуемого профессиональных навыков требованиям конкретной ситуации?`,
        ]}
      />,
      <Typography
        sx={{ fontSize: '1.2rem', m: '.5rem 0' }}
        children='VI. Установление психического состояния, предрасполагавшего к самоубийству:'
      />,
      <UI
        type='pointer'
        items={[
          `Находилось ли данное лицо в период, предшествовавший смерти, в психическом состоянии, предрасполагающем к самоубийству?`,
          `Если обследуемое лицо находилось в предшествующий смерти период в предрасполагающем к самоубийству состоянии, чем это состояние могло быть вызвано?`,
        ]}
      />,
      <Typography
        sx={{ fontSize: '1.2rem', m: '.5rem 0' }}
        children='VII. Установление у испытуемого лица особенностей, которые могли способствовать совершению противоправных действий:'
      />,
      <UI
        type='pointer'
        items={[
          `Имеются ли у обвиняемого индивидуально-психологические особенности (интеллектуальные, характерологические, эмоционально-волевые, мотивационные и др.), которые могли существенно повлиять на его поведение в исследуемой ситуации?`,
        ]}
      />,
      <Typography
        sx={{ fontSize: '1.2rem', m: '.5rem 0' }}
        children='Наиболее распространенными в гражданском процессе являются следующие виды психологических экспертиз, устанавливающих:'
      />,
      <UI
        type='pointer'
        items={[
          `эмоциональное состояние;`,
          `способность стороны в процессе, в том числе несовершеннолетней, в полной мере осознавать значение своих действий и (или) в полной мере руководить ими;`,
          `способность сторон, свидетелей, как взрослых, так и несовершеннолетних, правильно воспринимать имеющие значение для дела обстоятельства и давать о них правильные объяснения, показания;`,
          `личностные особенности сторон в деле;`,
          `особенности функционирования в человеко-машинных системах (при выполнении человеком профессиональных функций в области управления современной техникой) - преимущественно в отношении ответчиков по регрессным искам;`,
          `содержание и иерархия основных мотивационных линий личности.`,
        ]}
      />,
      ...[
        `БЦСЭиГ также осуществялет проведение видео-психологической экспертизы и предоставляет услуги по проведению психологических исследований (споры о детях, для оформления опеки и др.).`,
        `Решение вопросов по:`,
      ].map((el) => (
        <Typography sx={{ fontSize: '1.2rem', m: '.5rem 0' }} children={el} />
      )),
      <UI
        type='pointer'
        items={[
          `определению места жительства ребенка;`,
          `определению порядка общения с ребенком;`,
          `определения места жительства и общения с ребенком и др.`,
        ]}
      />,
      ...[`Решая поставленные вопросы, эксперт проводит исследования:`].map(
        (el) => (
          <Typography sx={{ fontSize: '1.2rem', m: '.5rem 0' }} children={el} />
        )
      ),
      <UI
        type='pointer'
        items={[
          `Индивидуально-психологических особенностей родителей, стиля воспитания, ценностных ориентаций и основных мотивационных линий поведения, влияющих на всестороннее развитие ребенка.`,
          `Индивидуально-психологических качеств и свойств ребенка, исследование особенностей его психического развития.`,
          `Установление психологически целесообразного места проживания ребенка.`,
          `Определение влияния семейных конфликтов на эмоциональное состояние и психическое развитие ребенка.`,
          `Выявление недопустимых форм обращения и способов воспитания ребенка: отвержения, эксплуатации, насилия, жестокости, пренебрежения и др. (с точки зрения соблюдения прав ребенка и ювенальных норм).`,
        ]}
      />,
    ],
    img: '',
  },
  {
    title: 'Радиотехническая экспертиза',
    hiddenAfterCount: 4,
    collapsed: true,
    description: [
      ...[
        `Радиотехническая экспертиза включает в себя изучение и исследование технического состояния, работы, выявление причин и причинно-следственных связей неполадок радиотехнического оборудования и систем.`,
        `Объектами радиотехнического исследования являются:`,
      ].map((el) => (
        <Typography sx={{ fontSize: '1.2rem', m: '.5rem 0' }} children={el} />
      )),

      <UI
        type='pointer'
        items={[
          `специальные технические средства для негласного получения информации;`,
          `специальные технические средства для несанкционированного отключения автомобильных охранных систем;`,
          `специальные технические средства для несанкционированного получения конфиденциальной клиентской информации систем дистанционного банковского обслуживания;`,
        ]}
      />,
      ...[`Вопросы, решаемые радиотехнической экспертизой:`].map((el) => (
        <Typography sx={{ fontSize: '1.2rem', m: '.5rem 0' }} children={el} />
      )),
      <UI
        type='pointer'
        items={[
          `Каково функциональное предназначение представленного на исследование устройства?`,
          `Возможно ли с помощью представленного на исследование устройства управлять охранными автомобильными системами?`,
          `Возможно ли с помощью представленного на исследование устройства создавать заградительные помехи в радиочастотном диапазоне.`,
          `Относиться ли представленное на исследование устройство к категории специальных технических средств (СТС).`,
        ]}
      />,
    ],
    img: '',
  },
  {
    title: 'Строительно-техническая экспертиза',
    hiddenAfterCount: 4,
    collapsed: true,
    description: [
      ...[
        `Строительно-техническая экспертиза назначается как по уголовным, так и по гражданским делам. Нарушение правил безопасности при производстве строительных работ уголовно наказуемо (ст. 216 УК). При предварительном расследовании и в суде специальные знания необходимы и для установления объема и качества строительно-монтажных работ, стоимости материалов, обоснованности проектов, механизма аварий.`,
        `При производстве по гражданским делам назначение экспертиз этого рода обычно связано с решением споров по вопросам найма жилого помещения (например, претензии могут быть предъявлены в соответствии со ст. 681 "Ремонт сданного внаем жилого помещения" ГК), строительного подряда (ст. 740-757 ГК), а также разделом домовладений, правомерностью их строительства и некоторыми другими.`,
        `Круг вопросов, решаемых строительно-технической экспертизой, чрезвычайно широк. Эти вопросы носят, как правило, диагностический характер и могут быть систематизированы в соответствии с задачами строительно-технической экспертизы.`,
        `I. Обоснованность строительно-технической документации, соответствие предъявляемым требованиям условий осуществления строительства и надзора за его производством:`,
      ].map((el) => (
        <Typography sx={{ fontSize: '1.2rem', m: '.5rem 0' }} children={el} />
      )),

      <UI
        type='pointer'
        items={[
          `Обоснованна ли данная проектно-сметная документация, соответствует ли она действующим строительным нормам и правилам (СНиП)? Не завышены ли объемы или стоимость работ, в какой степени?`,
          `В соответствии ли с действующими СНиПами оформлена исполнительно-техническая документация? Соответствует ли она проектно-сметной документации? Если нет, то в чем выражается несоответствие?`,
          `Соответствует ли требованиям СНиПов проект производства строительно-монтажных и ремонтных работ? Если нет, то в чем заключается несоответствие?`,
          `Были ли к началу выполнения строительно-монтажных или ремонтных работ составлены (утверждены, получены) все необходимые технические документы (технические проекты, сметы, чертежи)? Если нет, какие документы должны были быть составлены в дополнение к имеющимся?`,
          `Соответствует ли заключение по результатам инженерно-топологического исследования строительной площадки требованиям, предъявляемым к такого рода документам? Отвечает ли данная строительная площадка предъявляемым требованиям? Если нет, то в чем выражается ее несоответствие этим требованиям? Какие упущения имелись при производстве изыскательских работ на участке, выделенном под строительство?`,
          `Осуществлялся ли технический и авторский надзор за строительством в соответствии с действующими положениями и СНиПами? Какие положения, нормы и правила нарушены?`,
          `Правильно ли применены действующие нормы на списание строительных материалов в технических отчетах?`,
        ]}
      />,
      ...[
        `II. Оценка объема и качества строительно-монтажных и ремонтных работ, затрат и сроков его производства:`,
      ].map((el) => (
        <Typography sx={{ fontSize: '1.2rem', m: '.5rem 0' }} children={el} />
      )),
      <UI
        type='pointer'
        items={[
          `Каков фактический объем выполненных строительно-монтажных и ремонтных работ?`,
          `Соответствует ли объем работ, указанный в отчетах и нарядах, актам фактически выполненных работ по контрольным обмерам? Если допущены завышения (занижения), то на сколько? Какие факторы способствовали этому?`,
          `Соответствует ли действующим СНиПам качество примененных строительных материалов и изделий, качество отдельных частей зданий и сооружений? Соответствовали ли проектной и технической документации использованные материалы? Использовались ли при производстве строительно-монтажных и ремонтных работ строительные материалы и изделия, изготовленные с нарушением государственных стандартов и технических условий? Были ли работники обеспечены всеми необходимыми средствами для выполнения технического задания?`,
          `Является ли обоснованным финансирование строительства? Каковы размеры излишнего финансирования?`,
          `Сколько и каких материалов израсходовано на строительство (ремонт) данного объекта, соответствует ли фактический расход тому, что затребовано на производство? Была ли необходимость в истребовании дополнительных материалов? Переделывались ли какие-либо строительные работы? За счет каких материалов?`,
          `Не использованы ли при строительстве более дешевые материалы по сравнению с теми, которые предусмотрены технической документацией и фактически списанными на производство? Какие материалы, в каких количествах были заменены?`,
          `Использованы ли в нарядах установленные расценки и нормы рабочего времени? Какие отступления от них допущены? Правильно и обоснованно ли начислены премии руководящим и инженерно-техническим работникам, служащим строительно-монтажных и проектных организаций за ввод в действие в срок и досрочно производственных мощностей и объектов; специалистам проектных организаций за авторский надзор при производстве строительных и ремонтных работ?`,
          `Какова реальная экономия средств от внедрения данного рационализаторского предложения? Правильно ли исчислена выплаченная за него премия?`,
          `Позволяли ли данные условия (обеспеченность строительными материалами, оборудованием, рабочей силой и проч.) начать строительство в данный срок, завершить его к данному сроку? Состоятельны ли названные причины о нарушении сроков строительства?`,
        ]}
      />,
      ...[
        `III. Оценка правильности приемки и отчетности о выполненных строительно-монтажных и ремонтных работах:`,
      ].map((el) => (
        <Typography sx={{ fontSize: '1.2rem', m: '.5rem 0' }} children={el} />
      )),
      <UI
        type='pointer'
        items={[
          `Допущены ли какие-либо отступления от действующих правил приемки строительно-монтажных и ремонтных работ? Если да, то в чем заключаются эти отступления?`,
          `Правильно ли использованы расценки в актах приемки работ?`,
          `Правильно ли составлены документы государственной и материально-технической отчетности? Каковы обнаруженные отступления от правил и норм?`,
        ]}
      />,
      ...[
        `IV. Установление механизма аварии при выполнении строительно-монтажных и ремонтных работ, причинно-следственной связи аварии (происшествия) с несоблюдением СНиП и правил техники безопасности в строительстве:`,
      ].map((el) => (
        <Typography sx={{ fontSize: '1.2rem', m: '.5rem 0' }} children={el} />
      )),
      <UI
        type='pointer'
        items={[
          `Каков механизм разрушения здания, сооружения, конструкции?`,
          `Какие правила безопасности в строительстве были нарушены? Находились ли эти нарушения в причинно-следственной связи с аварией или несчастным случаем?`,
          `Соответствовал ли способ ведения работ (строительно-монтажных, ремонтно-строительных, земляных при разборке зданий и сооружений) указаниям проекта организации работ и СНиП? В чем состояли отклонения, имелась ли причинно-следственная связь между ними и происшедшим событием?`,
          `Соответствовал ли данный проект требованиям безопасности в строительстве? Достаточны ли для обеспечения безопасности меры, указанные в данном документе на производство работ, например, в наряде-допуске?`,
          `Как связана авария или нарушение требований безопасности с использованием данных строительных материалов, оборудования?`,
          `Соответствует ли квалификация потерпевшего характеру выполнявшихся им работ?`,
          `Соответствуют ли СНиПам и иным положениям и инструкциям использование и обеспечение работников средствами индивидуальной и коллективной защиты?`,
        ]}
      />,
      ...[
        `V. Вопросы, выносимые на разрешение строительно-технической экспертизы преимущественно по гражданским делам:`,
      ].map((el) => (
        <Typography sx={{ fontSize: '1.2rem', m: '.5rem 0' }} children={el} />
      )),
      <UI
        type='pointer'
        items={[
          `Какова общая (или полезная) площадь и действительная стоимость домовладения согласно существующим в настоящее время расценкам? Какова стоимость 1 кв. метра общей (полезной) площади домовладения?`,
          `Возможен ли технически раздел данного домовладения (в соответствии с санитарно-техническими нормами и нормами пожарной безопасности), учитывая долю каждого из домовладельцев (указать размер долей)? Если раздел невозможен, то по какой причине? Имеется ли техническая возможность выделения изолированной комнаты в квартире или квартиры в жилом доме?`,
          `Какие работы по переоборудованию домовладения необходимо провести и какие материальные затраты потребуются для этого по каждому из представленных вариантов раздела (возможно составление сметы на работы и материалы)?`,
          `Соответствует ли представленный проект жилого дома (надстройки, гаража и проч.) фактически выстроенному строению?`,
          `Возможна ли оценка конкретных строений (гаражей, дачных домов, надворных построек) и какова их стоимость?`,
          `Обоснованно ли определена сумма стоимости за выполнение работ по возведению строений, их частей, ремонтно-строительных работ?`,
          `Каков порядок пользования земельным участком в соответствии с идеальными долями?`,
          `Чем обусловлены повреждения здания, потолка, стен, пола квартиры? Каковы объем и стоимость ремонтно-строительных работ?`,
        ]}
      />,
      ...[
        `Основными объектами строительно-технической экспертизы являются:`,
      ].map((el) => (
        <Typography sx={{ fontSize: '1.2rem', m: '.5rem 0' }} children={el} />
      )),
      <UI
        type='pointer'
        items={[
          `строительные объекты (здания, сооружения и их части);`,
          `участки территории, где расположены строительные объекты;`,
          `строительные материалы и строительное оборудование;`,
          `проектно-сметная документация на производство строительно-монтажных и ремонтно-строительных работ, проекты производства работ;`,
          `договоры на производство строительно-монтажных и ремонтно-строительных работ;`,
          `исполнительная техническая документация (журналы работ, авторского надзора и проч.), материалы надзора со стороны заказчика (застройщика);`,
          `заключения инженерно-топологических исследований строительных участков;`,
          `акты и решения об отводе земельных участков под строительство, архитектурно-планировочные задания на разработку проектной документации, акты контрольных обмеров, приемки и обследования работ, строительных материалов и изделий;`,
          `наряды на оплату за выполненные строительно-монтажные и ремонтные работы, документы на выплату премий и надбавок;`,
          `материально-техническая отчетность о выполненных работах, списании стройматериалов;`,
          `акты обследования несчастных случаев и технических причин аварий при строительно-монтажных и ремонтных работах;`,
          `оценочные акты на домовладения, составленные бюро технической инвентаризации зданий (БТИ);`,
          `справки БТИ, содержащие данные о домовладении: размеры долей совладельцев, площадь подлежащего разделу строения и проч.;`,
          `документы, подтверждающие право собственности на домовладение (договор купли-продажи, дарения, решение суда и т.п.);`,
          `другие материалы, такие как данные о профессиональной подготовке работников, акты приемочных испытаний, технических осмотров оборудования, сведения о сырье, обрабатываемом при производстве строительных работ, и иные документы.`,
        ]}
      />,
    ],
    img: '',
  },
  {
    title: 'Техническая экспертиза документов',
    hiddenAfterCount: 4,
    collapsed: true,
    description: [
      ...[
        `Техническая экспертиза документов (ТЭД) проводит­ся в целях установления способа изготовления документа, определения использованных при этом технических средств и материалов, установления факта внесения в документ каких-либо изменений, восстановления содержания измененных и поврежденных документов.`,
        `Техническая экспертиза документов включает следующие виды исследований:`,
      ].map((el) => (
        <Typography sx={{ fontSize: '1.2rem', m: '.5rem 0' }} children={el} />
      )),

      <UI
        type='pointer'
        items={[
          `Определение абсолютной давности изготовления реквизитов документа.`,
          `Определение относительной давности изготовления печатного текста документа.`,
          `Определение относительной давности нанесения оттисков печатей и штампов документа.`,
          `Исследование оттисков печатей и штампов.`,
          `Исследование аудиовизуальной продукции.`,
          `Исследование ценных бумаг (векселя, федеральные специальные марки на алкогольную и табачную продукцию и т.д.).`,
          `Исследование документов по установлению последовательности нанесения реквизитов документов, в том числе без мест пересечения.`,
          `Исследование бланков документов (паспорт гражданина РФ, паспорт транспортного средства, водительские документы и т.д.).`,
        ]}
      />,
      ...[`Данный вид экспертизы решает следующие подгруппы вопросов:`].map(
        (el) => (
          <Typography sx={{ fontSize: '1.2rem', m: '.5rem 0' }} children={el} />
        )
      ),
      <UI
        items={[
          <Typography
            sx={{ fontSize: '1.2rem', m: '.5rem 0' }}
            children='Вопросы, решаемые при установлении давности изготовления документов:'
          />,
          <UI
            type='pointer'
            items={[
              `Соответствует ли время изготовления документа дате, указанной в нем?`,
              `Подвергался ли документ искусственному старению?`,
              `Какова последовательность выполнения реквизитов документа?`,
              `Какова относительная давность выполнения реквизитов документа?`,
            ]}
          />,
          <Typography
            sx={{ fontSize: '1.2rem', m: '.5rem 0' }}
            children='Вопросы, решаемы при установлении способа изготовления документа:'
          />,
          <UI
            type='pointer'
            items={[
              `Каким способом изготовлен документ?`,
              `Каким способом выполнены реквизиты документа?`,
            ]}
          />,
          <Typography
            sx={{ fontSize: '1.2rem', m: '.5rem 0' }}
            children='Вопросы, решаемые при проведении идентификационных исследований:'
          />,
          <UI
            type='pointer'
            items={[
              `Не этой ли печатью (штампом) нанесен оттиск печати (штампа) в документе?`,
            ]}
          />,
          <Typography
            sx={{ fontSize: '1.2rem', m: '.5rem 0' }}
            children='Вопросы, решаемые при установлении способа изменения документов:'
          />,
          <UI
            type='pointer'
            items={[
              `Имеются ли в документе признаки монтажа?`,
              `Подвергался ли документ агрессивному воздействию?`,
            ]}
          />,
        ]}
      />,
    ],
    img: '',
  },
  {
    title: 'Финансово-экономическая экспертиза',
    hiddenAfterCount: 4,
    collapsed: true,
    description: [
      ...[
        `Финансово-экономическая экспертиза назначается для решения задач, касающихся финансовой деятельности организаций, определения их финансового состояния, соблюдения законодательных актов, регулирующих их финансовые отношения с государственным бюджетом (определение размера неправомерно полученных доходов в результате несоблюдения правил совершения финансовых операций, скрытой от государства прибыли, необоснованных отчислений в денежные фонды и т.д.), выполнения договорных обязательств, распределения и выплаты дивидендов, операций с ценными бумагами, инвестициями и проч.`,
        `Финансово-экономическая экспертиза позволяет разрешать следующие диагностические задачи:`,
      ].map((el) => (
        <Typography sx={{ fontSize: '1.2rem', m: '.5rem 0' }} children={el} />
      )),
      <UI
        type='pointer'
        items={[
          `определение реальности и экономической обоснованности финансовых показателей организации (предприятия) в случаях искажения ее данных о доходах и расходах;`,
          `исследование финансового состояния и платежеспособности организации (предприятия);`,
          `анализ структуры и динамики доходов и расходов организации (предприятия) в целях определения в них диспропорций, повлиявших на величину балансовой прибыли и связанных с ней показателей;`,
          `выявление диспропорций в соотношении между себестоимостью продукции и динамикой применяемых цен в целях установления фактов искажения отчетных данных о прибыли организации (предприятия);`,
          `установление негативных отклонений в распределении и использовании прибыли, приведших к необоснованному завышению сумм денежных средств, оставляемых в распоряжении организации (предприятия);`,
          `анализ расчетных операций, связанных с образованием и использованием доходов и денежных фондов организации (предприятия), в целях установления негативных отклонений (недоплат, переплат) по отдельным статьям доходов и направлениям расходования денежных средств;`,
          `определение степени обеспечения предприятия оборотными средствами, причин образования дебиторской и кредиторской задолженности;`,
          `анализ финансовых ситуаций, связанных с завышением продажных цен, занижением закупочных, превышением предельного уровня рентабельности и получением сверхприбыли предприятиями-монополистами.`,
        ]}
      />,
      ...[
        `Типичными вопросами диагностического характера являются следующие:`,
      ].map((el) => (
        <Typography sx={{ fontSize: '1.2rem', m: '.5rem 0' }} children={el} />
      )),
      <UI
        items={[
          `Имеются ли (и какие именно) нарушения требований нормативных актов в части установления цен на изготовляемую продукцию, получения завышенной прибыли (доходов)?`,
          `Составлен ли финансовый план организации (предприятия) достаточно обоснованно, с соблюдением всех необходимых требований?`,
          `Не допущено ли фальсификации финансовых расчетов плана дохода и расхода (расчетов издержек на производство, образования специальных фондов на ремонт основных средств, модернизацию их и проч.)?`,
          `Соответствуют ли отчетные данные предприятия его финансовому состоянию; если нет, то какие экономические факторы привели его к неплатежеспособности?`,
          `Соблюдаются ли при осуществлении финансово-кредитных операций требования законодательных актов, например, о распределении прибылей и дивидендов между отечественными и зарубежными партнерами, инвестиционным фондом и мелкими инвесторами и проч.?`,
          `Имеются ли на предприятии (организации) нарушения при производстве банковских кредитных и расчетных операций?`,
          `Обоснованно ли образование данных фондов потребления и накопления предприятием в результате его финансово-хозяйственной деятельности за ... год?`,
          `Позволяло ли финансовое состояние предприятия предоставить ссуду другому предприятию; если да, то за счет каких финансовых источников?`,
          `В какой мере оправданным является увеличение непроизводственных (коммерческих) потерь на реализацию продукции, предусмотренных в финансовом плане доходов и расходов?`,
          `Не свидетельствуют ли финансовые операции предприятия об отвлечении денежных средств из хозяйственного оборота и их нецелевом использовании?`,
          `Обоснованны ли были требования руководства предприятия об основных или дополнительных ассигнованиях из бюджета?`,
          `Обоснованно ли израсходована в данной организации (предприятии) указываемая сумма кредита? Израсходованы ли данные средства (например, заработная плата работникам) по назначению?`,
          `В полном ли объеме и в должные ли сроки проведены предприятием данные платежи в госбюджет, по договорным обязательствам?`,
          `Соответствует ли себестоимость продукции (работ, услуг) предприятия произведенным затратам; если да, то не повлекло ли это к занижению данных о прибыли предприятия?`,
        ]}
      />,
      ...[
        `Объекты финансово-экономической экспертизы во многом схожи с объектами судебно-бухгалтерских экспертиз и включают в первую очередь документацию, дающую представление о финансово-кредитной деятельности организации (предприятия), те документы, которые отражают затраты и финансовые результаты ее деятельности, финансовые источники и направления их использования. К ним относятся договоры между предприятиями и организациями различных форм собственности и приложения к ним, такие накопительные документы бухгалтерского учета, как журналы-ордера (мемориальные ордера), ведомости и другие регистры, Главная книга, или книга регистрации хозяйственных операций, а также Баланс предприятия и приложения к нему, включая отчет о финансовых результатах и их использовании. Направляемые на экспертизу материалы должны быть отобраны с такой полнотой, которая обеспечивает комплексное представление системы данных, отражающих балансовые взаимосвязи оборотов по счетам.`,
        `При отсутствии регистров бухгалтерского учета (Главной книги, а также журналов-ордеров) для экспертного исследования могут быть направлены данные соответствующим образом подобранных первичных документов. Например, платежные поручения должны быть сгруппированы в хронологической последовательности с приложением счетов-фактур, товарно-транспортных накладных и других документов. Выписки банка должны соответствовать балансовым данным и регистрам учета самого банка и т.д.`,
      ].map((el) => (
        <Typography sx={{ fontSize: '1.2rem', m: '.5rem 0' }} children={el} />
      )),
    ],
    img: '',
  },
  {
    title: 'Фоноскопическая экспертиза',
    hiddenAfterCount: 4,
    collapsed: true,
    description: [
      ...[
        `Фоноскопическая экспертиза проводится в целях: установления личности говорящего по признакам голоса и речи, записанной на фонограмме, выявления признаков монтажа и иных изменений, привнесенных в фонограмму в процессе или после окончания звукозаписи, определения условий, обстоятельств, средств и материалов звукозаписи, а также иных фактов по фонограммам, имеющим доказательственное значение при расследовании уголовных дел.`,
        `Задачи, составляющие предмет фоноскопической экспертизы носят идентификационный и диагностический характер и решаются на основе комплекса специальных познаний в радиотехнических, физико-математических, информационных и лингвистических технологиях. К идентификационным задачам относится, например, идентификация человека по голосу и речи, идентификация устройства, на котором производилась запись представленной фонограммы.  К диагностическим - установление свойств источников звуков по записанным на фонограмме акустическим сигналам.`,
        `Объектами фоноскопической экспертизы являются фонодокументы и фонограммы, приобщенные к материалам уголовного дела в качестве вещественных доказательств. Фонограммы могут быть зафиксированы в аналоговой форме на магнитной ленте, кассете, микрокассете, видеокассете, катушке, либо в цифровой форме на магнитном или электронном носителе.`,
        `Вопросы,  решаемые фоноскопической экспертизой:`,
      ].map((el) => (
        <Typography sx={{ fontSize: '1.2rem', m: '.5rem 0' }} children={el} />
      )),
      <UI
        type='pointer'
        items={[
          `Имеется ли на фонограмме (указывается местонахождение подлежащей исследованию фонограммы на представляемом носителе) голос и речь подозреваемого (указывается фамилия и инициалы), чьи образцы представлены на кассете (описывается тип носителя записи). Если имеется, то какие реплики, слова или фразы им произнесены?`,
          `Одним или разными лицами произнесены реплики, обозначенные в тексте стенограммы как (указывается форма условного обозначения) на фонограммах 1 и 2 и т.д.?`,
          `Сколько лиц принимало участие в разговоре, записанном на фонограмме на (указывается местонахождение и описывается тип носителя звукозаписи)?`,
          `Установить дословное содержание разговора, записанного на фонограмме на (указывается тип носителя звукозаписи и описывается местонахождение фонограммы, указываются словесные границы фонограммы).`,
          `Произвести перезапись представленной фонограммы с очисткой от шумов и помех.`,
          `Имеются ли на фонограмме признаки монтажа или иных изменений, привнесенных в процессе или после производства звукозаписи?`,
          `Является ли представленная фонограмма копией фонограммы, записанной на (указывается местонахождение и тип носителя)?`,
          `Была ли представленная фонограмма изготовлена с помощью представленного магнитофона (указывается тип записывающего устройства)?`,
          `Установить номер телефона, набор которого акустически зафиксирован на представленной фонограмме.`,
        ]}
      />,
      ...[
        `Указанный перечень вопросов не является исчерпывающим. При назначении фоноскопической экспертизы по конкретному уголовному делу могут быть сформулированы и другие вопросы, способствующие установлению фактов и обстоятельств, имеющих доказательственное значение. Поэтому, желательно, до вынесения постановления о назначении фоноскопической экспертизы получить квалифицированную консультацию специалиста.`,
        `Объекты, представляемые на фоноскопическую экспертизу, должны быть надлежащим образом упакованы и опечатаны, исключающем их размагничивание в процессе транспортировки. В постановлении о назначении фоноскопической экспертизы,  в обязательном порядке, должны быть воспроизведены из материалов уголовного дела и описаны технические средства, материалы и условия проведения звукозаписи, четко указаны границы и местонахождение на представляемом носителе каждой подлежащей исследованию фонограммы (с указанием начальных и конечных слов разговора или иных маркеров, показателей счетчика магнитофона, таймера и т.п.).`,
        `Для решения задачи идентификации лица по голосу и речи в распоряжение эксперта необходимо предоставить сравнительные образцы (экспериментальные или свободные) голоса и речи подозреваемого лица.`,
        `Экспериментальные образцы голоса и речи отбираются в порядке статьи 202 УПК России.`,
        `Свободные образцы голоса и речи подозреваемого, которые по времени и характеру не связаны с назначением и производством фоноскопической экспертизы, изымаются в порядке статьи 189 УПК России. Свободными образцами речи являются звукозаписи различных конференций, интервью, публичных выступлений по радио и телевидению, фонограммы личного характера и т.д.`,
        `В качестве условно свободных образцов голоса и речи могут быть представлены фонограммы допросов, очных ставок и иных следственных действий, в ходе которых применяется звукозапись в порядке, установленном процессуальным законом.`,
        `Основное требование, предъявляемое к фонограммам, представляемым в качестве сравнительных образцов, – несомненность их происхождения от подозреваемого лица, хорошее качество и представительность, как по объему, так и по характеру   речевого материала. Сравнительные образцы голоса и речи должны быть по длительности не менее 10–15 минут, записаны на аппаратуре не ниже первого класса, быть сопоставимыми со спорной фонограммой.`,
      ].map((el) => (
        <Typography sx={{ fontSize: '1.2rem', m: '.5rem 0' }} children={el} />
      )),
    ],
    img: '',
  },
  {
    title: 'Фототехническая экспертиза',
    hiddenAfterCount: 4,
    collapsed: true,
    description: [
      ...[
        `Фототехническая экспертиза проводится в целях идентификации объектов по их фотоизображениям с установлением условий съемки и обработки фотоматериалов, идентификации материалов и лабораторных принадлежностей по следам на негативах и позитивах, установления типа и марки «неизвестных» пленок, выявления на изображениях признаков монтажа и ретуши, установления других обстоятельств, связанных с фотосъемкой и обработкой фотоматериалов.`,
        `Вопросы, решаемые при идентификации кино-, фотоаппаратуры, лабораторного оборудования и фотоснимков:`,
      ].map((el) => (
        <Typography sx={{ fontSize: '1.2rem', m: '.5rem 0' }} children={el} />
      )),
      <UI
        type='pointer'
        items={[
          `Не экспонировалась ли представленная фотопленка (негатив) данной фотокамерой?`,
          `Не экспонировались ли представленные негативы одной фо­токамерой?`,
          `Не использовалась ли для получения представленных нега­тивов данная кассета (или одна и та же кассета)?`,
          `Не изготовлены ли представленные фотоотпечатки с по­мощью данного фотоувеличителя (кадрирующей или копироваль­ной рамки, контактного станка); одного и того же фотоувеличите­ля (кадрирующей или копировальной рамки, контактного станка)?`,
          `Не использовался ли при глянцевании представленных от­печатков данный (один и тот же) глянцеватель?`,
        ]}
      />,
      ...[
        `На экспертизу поступают прижимные стекла, глянцеватели и другое оборудование, не подвергавшееся чистке. `,
        `При невозможности представить на экспертизу крупногабарит­ную репродукционную установку с ее помощью получают экспери­ментальные образцы в том же масштабе, что и исследуемое фо­тоизображение. `,
        `На экспертизу представляют экспериментальные негативы, фо­тоаппарат репродукционной установки и экран для размещения объектов съемки (либо масштабный снимок экрана).`,
        `При невозможности представить значительный по размерам глянцеватель с его помощью также получают экспериментальные образцы. Для этого достаточное для перекрытия всей поверхности барабана количество листов фотобумаги засвечивают, проявляют, фиксируют, промывают, располагают встык на барабане, отмечая на них порядок расположения, и глянцуют. Таким же образом го­товят экспериментальные образцы из незасвеченных, непроявлен­ных, но отфиксированных листов бумаги. Их также представляют на экспертизу.`,
        `Вопрос, решаемый при идентификации негатива по позитивам:`,
      ].map((el) => (
        <Typography sx={{ fontSize: '1.2rem', m: '.5rem 0' }} children={el} />
      )),
      <UI
        type='pointer'
        items={[
          `Не получены ли представленные позитивы с данного (одного и того же) негатива?`,
        ]}
      />,
      ...[
        `Вопрос, решаемый при идентификации предметов (помещений, участков местности), изображенных на фотоснимках:`,
      ].map((el) => (
        <Typography sx={{ fontSize: '1.2rem', m: '.5rem 0' }} children={el} />
      )),
      <UI
        type='pointer'
        items={[
          `Изображен ли на негативах (отпечатках) данный (один и тот же) предмет (помещение, участок местности)?`,
        ]}
      />,
      ...[
        `Вопросы, решаемые при определении размеров изображенных на фотоснимке предметов и расстояний между ними:`,
      ].map((el) => (
        <Typography sx={{ fontSize: '1.2rem', m: '.5rem 0' }} children={el} />
      )),
      <UI
        type='pointer'
        items={[
          `Какие размеры имеет в натуре предмет, изображенный на фотоснимке?`,
          `Каково расстояние в натуре между предметами (деталями предметов), изображенными на фотоснимке?`,
        ]}
      />,
      ...[
        `Вопросы, решаемые при установлении условий съемки и изготовления фотоснимков:`,
      ].map((el) => (
        <Typography sx={{ fontSize: '1.2rem', m: '.5rem 0' }} children={el} />
      )),
      <UI
        type='pointer'
        items={[
          `Фотоаппарат какого типа, вида использовался при изготовлении данного фотоснимка?`,
          `Какой объектив использовался при съемке - нормальный, широкоугольный, длиннофокусный?`,
          `При каком освещении (солнечном или искусственном) про­изводилась съемка?`,
          `Изготовлен ли представленный фотоснимок с соблюдением технологических правил?`,
          `Какими причинами обусловлены дефекты данного снимка?`,
          `Применялась ли при изготовлении представленного снимка ретушь?`,
          `Является ли представленный снимок репродукцией или сним­ком с натуры?`,
          `Применялся ли при изготовлении данного снимка фотомон­таж?`,
        ]}
      />,
      ...[`Вопросы, решаемые при установлении типа фотоматериалов:`].map(
        (el) => (
          <Typography sx={{ fontSize: '1.2rem', m: '.5rem 0' }} children={el} />
        )
      ),
      <UI
        type='pointer'
        items={[
          `На фотобумаге (фотопленке) какого типа и вида изготов­лен данный отпечаток (негатив)?`,
          `Не изготовлены ли представленные отпечатки (негативы) на фотобумаге (фотопленке) одного и того же типа и вида?`,
          `Не изготовлены ли представленные отпечатки (негативы) и отпечатки (негативы), изъятые у подозреваемого, на фотобумаге (фотопленке) одного и того же типа и вида?`,
          `Какие обрабатывающие растворы использовались при изго­товлении представленного отпечатка (негатива)?`,
          `Каков тип (состав) представленного на исследование обра­батывающего вещества?`,
        ]}
      />,
      ...[`Вопрос, решаемый при установлении первоначального изображения:`].map(
        (el) => (
          <Typography sx={{ fontSize: '1.2rem', m: '.5rem 0' }} children={el} />
        )
      ),
      <UI
        type='pointer'
        items={[
          `Какое изображение имеется на представленном фотоснимке (выцветшем, нерезком, изготовленном с нарушением технологичес­ких правил)?`,
        ]}
      />,
    ],
    img: '',
  },
  {
    title: 'Экологическая экспертиза',
    hiddenAfterCount: 4,
    collapsed: true,
    description: [
      ...[
        `Экология - понятие собирательное, и соответственно проблема охраны окружающей среды - комплексная по своему характеру. Она имеет экономический, технологический, социальный, биологический, медицинский, административный и хозяйственный аспекты, включая при этом соответствующие правовые нормы, регулирующие указанные области отношений. Одно из прикладных направлений экологии - экологическая экспертиза, целями которой являются: установления источника, механизма, характеристики и масштабов негативного антропогенного воздействия на окружающую среду.`,
        `К задачам судебно-экологической экспертизы, как класса судебных экспертиз, относятся:`,
      ].map((el) => (
        <Typography sx={{ fontSize: '1.2rem', m: '.5rem 0' }} children={el} />
      )),
      <UI
        type='pointer'
        items={[
          `определение вида и местоположения источника негативного антропогенного воздействия;`,
          `характеристика (свойства) негативного антропогенного воздействия на окружающую среду во времени и пространстве;`,
          `установление механизма негативного антропогенного воздействия;`,
          `определение масштабов, а также выявление условий и обстоятельств, способствующих усилению негативного антропогенного воздействия;`,
          `установления обстоятельств, связанных с нарушением природоохранного законодательства, условий эксплуатации потенциально опасных объектов и действий (бездействия) специально уполномоченных лиц в области охраны окружающей среды и природопользования, которые способствовали причинению вреда здоровью человека (смерти человека) или иных тяжких последствий.`,
        ]}
      />,
      ...[`Объекты судебной экологической экспертизы:`].map((el) => (
        <Typography sx={{ fontSize: '1.2rem', m: '.5rem 0' }} children={el} />
      )),
      <UI
        type='pointer'
        items={[
          `локальный земельный участок, где обнаружены признаки негативного антропогенного воздействия;`,
          `пробы атмосферного воздуха, воды, почвы, отобранные в пределах антропогенно-нарушенного участка;`,
          `образцы флоры и фауны, в том числе и на микроуровне, подвергшиеся антропогенному воздействию;`,
          `механизмы, оборудование или узлы, детали с места происшествия (в прилагаемых к нему схемах и иллюстрациях, в протоколах следственных экспериментов);`,
          `сведения из технической документации и актов проверки экологического состояния объектов, другие источники информации о негативном антропогенном воздействии.`,
        ]}
      />,
      ...[
        `На разрешение судебной экологической экспертизы могут быть поставлены следующие вопросы:`,
      ].map((el) => (
        <Typography sx={{ fontSize: '1.2rem', m: '.5rem 0' }} children={el} />
      )),
      <UI
        type='pointer'
        items={[
          `Где находится источник негативного антропогенного воздействия (например, потенциально опасный объект, в результате деятельности которого оно произошло)?`,
          `Является ли конкретный участок местности (потенциально опасный объект) местом возникновения негативного антропогенного воздействия?`,
          `Сколько имелось источников негативного антропогенного воздействия (например, в случае наличия на месте происшествия нескольких потенциально опасных объектов), каковы их взаимосвязь и последовательность негативного воздействия на окружающую среду?`,
          `Каковы пути распространения веществ, опасных для здоровья людей и (или) ухудшающих состояние окружающей среды?`,
          `Какие условия способствовали увеличению масштабов негативного антропогенного воздействия?`,
          `Чем объясняются отмеченные вследствие негативного антропогенного воздействия явления (например, интенсивное поступление в атмосферу дыма и пыли)?`,
          `В течение какого периода отмечаются отрицательные последствия негативного антропогенного воздействия на окружающую среду?`,
          `В течение какого периода возможно конкретное негативное антропогенное воздействие?`,
          `Какова непосредственная техническая причина возникновения негативного антропогенного воздействия?`,
          `Имеются ли на объектах, представленных на экспертизу, признаки аварийных процессов? Каковы причины их возникновения?`,
          `Каков механизм возникновения и развития аварийного режима на данном потенциально опасном объекте?`,
          `Соответствует ли устройство очистных и иных природоохранных сооружений экологическим требованиям, в том числе указанным в заключении государственной экологической экспертизы?`,
          `Имеются ли на объектах, представленных на экспертизу, следы загрязняющих и иных веществ, отрицательно влияющих на экологическое состояние окружающей среды? Если да, то каких?`,
          `Имеются ли на окружающих предметах, в том числе зафиксированных на фотоснимках (видеоматериалах), следы загрязняющих и иных веществ, отрицательно влияющих на экологическое состояние окружающей среды?`,
          `Относятся ли вещества и материалы (указать - какие) к группе потенциально опасных для окружающей среды? Возможно ли самопроизвольное распространение этих веществ в определенных (указать - каких) условиях?`,
          `Имеются ли на данном объекте несоответствия экологическим требованиям, находящиеся в причинно-следственной связи с возникновением негативного антропогенного воздействия или способствовавшие быстрому распространению загрязняющих и иных веществ, отрицательно влияющих на экологическое состояние окружающей среды; если да, то в чем они состоят?`,
          `Подлежал ли потенциально опасный объект (субъект негативного антропогенного воздействия) оборудованию системами оповещения и управления эвакуацией людей, а также системами защиты от аварий, влияющих на окружающую среду?`,
          `Выполнили ли данные автоматические системы защиты от аварий, влияющих на окружающую среду, при негативном антропогенном воздействии свою задачу (сработали ли они)?`,
          `Находится ли отсутствие систем защиты от аварий, влияющих на окружающую среду, на потенциально опасном объекте в причинно-следственной связи с получением травм или гибелью человека (людей) при негативном антропогенном воздействии?`,
          `Находится ли отсутствие систем оповещения и управления эвакуацией людей на потенциально опасном объекте в причинно-следственной связи с получением травм или гибелью человека (людей) в результате негативного антропогенного воздействия?`,
        ]}
      />,
      ...[
        `Судебно-экологическая экспертиза является формирующимся классом судебных экспертиз, в котором выделяются следующие роды:`,
      ].map((el) => (
        <Typography sx={{ fontSize: '1.2rem', m: '.5rem 0' }} children={el} />
      )),
      <UI
        type='pointer'
        items={[
          `судебная экспертиза экологического состояния почвенно-геологических объектов;`,
          `судебная экспертиза экологического состояния биогеоценозов;`,
          `судебная экспертиза экологического состояния водных объектов;`,
          `судебная экспертиза экологического состояния атмосферного воздуха;`,
          `судебная экспертиза радиационной обстановки;`,
          `судебная экспертиза воздействия хозяйствующего субъекта на окружающую среду и др.`,
        ]}
      />,
      ...[
        `Исследование экологического состояния почвенно-геологических объектов (судебная эколого-почвоведческая экспертиза) - комплекс действий, производимых в установленной законом форме специалистами в области почвоведения, экологии и смежных естественных наук, которые дают заключение по вопросам, связанным с исследованием негативного антропогенного воздействия на почвенно-геологические объекты.`,
        `Предмет судебной эколого-почвоведческой экспертизы - фактические обстоятельства, устанавливаемые на основе специальных естественно-научных знаний в области землепользования и охраны почв, а также исследований материалов дел по фактам негативного антропогенного воздействия на почвенно-геологические объекты.`,
        `Исследование экологического состояния биоценозов - комплекс действий, производимых в установленной законом форме специалистами в области биологии и экологии, которые дают заключение по вопросам, связанным с исследованием негативного антропогенного воздействия на биоценозы. Предмет этой судебной экспертизы - фактические обстоятельства, устанавливаемые на основе специальных естественно-научных знаний в области лесопользования и охраны растительных объектов, а также исследований материалов дел по фактам негативного антропогенного воздействия на биоценозы.`,
        `Судебная экологическая экспертиза радиационной обстановки - это комплекс действий, производимых в установленной законом форме специалистами в области радиоэкологии, которые дают заключение по вопросам, связанным с негативным воздействием радиации на окружающую среду. Ее предметом являются фактические данные, устанавливаемые на основе специальных естественно-научных знаний в области радиоэкологии, а также исследований материалов дел по фактам негативного радиационного воздействия.`,
      ].map((el) => (
        <Typography sx={{ fontSize: '1.2rem', m: '.5rem 0' }} children={el} />
      )),
    ],
    img: '',
  },
  {
    title: 'Экспертиза материалов, веществ и изделий',
    hiddenAfterCount: 4,
    collapsed: true,
    description: [
      ...[
        `Криминалистическая экспертиза материалов, веществ и изделий (КЭМВИ) - направление в криминалистике, которое предпола­гает комплексное исследование определенного рода объектов, пос­тоянно встречающихся в следственно-судебной практике: микрообъектов, малых количеств (объемов) материалов (веществ); тра­диционных объектов, для исследования которых трасологические методы оказываются неэффективными. Исследования проводятся с использованием физико-химических инструментальных и собственно криминалистических методов.`,
        `Специфика этой экспертизы отражается в разрешаемых ею за­дачах как по обнаружению и установлению природы, назначения, происхождения различных материалов, веществ и изделий, так и идентификационного характера - по установлению целого по частям (в широком аспекте), факта контактного взаимодействия и др.`,
        `В зависимости от объектов и решаемых задач различают сле­дующие основные виды криминалистической экспертизы материа­лов, веществ и изделий:`,
      ].map((el) => (
        <Typography sx={{ fontSize: '1.2rem', m: '.5rem 0' }} children={el} />
      )),
      <UI
        type='pointer'
        items={[
          `экспертиза лакокрасочных материалов (покрытий);`,
          `экспертиза объектов волокнистой природы;`,
          `экспертиза нефтепродуктов, горюче-смазочных материалов и легковоспламеняющихся жидкостей;`,
          `экспертиза продуктов выстрела;`,
          `экспертиза силикатных материалов (стекло, керамика, вяжущие материалы) и изделий из них;`,
          `экспертиза металлов, сплавов и изделий из них;`,
          `экспертиза наркотических средств, психотропных, сильнодействующих, ядовитых и одурманивающих ве­ществ;`,
          `экспертиза специальных химических веществ;`,
          `экспертиза полимерных материалов и изделий из них.`,
        ]}
      />,
      ...[
        `Вопросы, решаемые при исследовании лакокрасочных материалов (покрытий):`,
      ].map((el) => (
        <Typography sx={{ fontSize: '1.2rem', m: '.5rem 0' }} children={el} />
      )),
      <UI
        type='pointer'
        items={[
          `Является ли данный объект лакокрасочным материалом?`,
          `Имеются ли на одежде (предметах, представленных на иссле­дование) наслоения лакокрасочных материалов и покрытий?`,
          `Каков вид данного лакокрасочного покрытия, и каково его целевое назначение; в частности, является ли представленная на экспертизу частица фрагментом автомобильного лакокрасочного покрытия?`,
          `Какой краской (лаком, эмалью) был покрыт предмет до пе­рекрашивания? Каков его первоначальный цвет?`,
          `Использовалась ли краска (лак, эмаль), изъятая у подозре­ваемого, для окрашивания того или иного объекта?`,
          `Однородны ли образцы лакокрасочного покрытия подокон­ника (дверного проема и пр.) и частицы краски, обнаруженные на одежде подозреваемого?`,
        ]}
      />,
      ...[
        `На экспертизу представляют объекты, на которых находится (может находиться) лакокрасочное покрытие либо частицы лако­красочного покрытия, изъятые с места происшествия.`,
        `Соскобы проб лакокрасочных покрытий с предметов, оставив­ших следы на месте происшествия, делаются с участков, которые могли непосредственно находиться в соприкосновении.`,
        `Образцы лакокрасочных покрытий отдельных предметов, а так­же фрагменты лакокрасочного покрытия, изымаемые с места про­исшествия, упаковываются в стеклянные бюксы, пластмассовые коробочки, между предметными стеклами, имеющими лунки, в бу­мажные свертки и снабжаются пояснительными надписями.`,
        `Недо­пустимо использование для этих целей дактилоскопических пленок и других липких лент.`,
        `Вопросы, решаемые при исследовании объектов волокнистой природы:`,
      ].map((el) => (
        <Typography sx={{ fontSize: '1.2rem', m: '.5rem 0' }} children={el} />
      )),
      <UI
        type='pointer'
        items={[
          `Являются ли представленные микрообъекты текстильными волокнами? Если да, то сохранили ли данные волокна характерные признаки текстильного материала, от которого они отделены? Если да, то от какого изделия они отделены?`,
          `Являются ли представленные микрообъекты текстильными волокнами? Если да, то пригодны ли для идентификации?`,
          `Имеются ли на поверхности предметов одежды потерпевшего (обивке сидения автомашины и пр.) волокна-наслоения, сходные с волокнами, входящими в состав предметов одежды подозреваемого? Имеются ли на поверхности предметов одежды подозреваемого волокна-наслоения, сходные с волокнами, входящими в состав предметов одежды потерпевшего (обивке сидения автомашины и пр.)? Если да, то какова их локализация; указывают ли обнаруженные наслоения на возможность контакта данных предметов?`,
        ]}
      />,
      ...[
        `На экспертизу представляют текстильные волокна, изъятые с места происшествия, проверяемые изделия.`,
        `Вопросы, решаемые при исследовании нефтепродуктов, горюче-смазочных материалов и легковоспламеняющихся жидкостей:`,
      ].map((el) => (
        <Typography sx={{ fontSize: '1.2rem', m: '.5rem 0' }} children={el} />
      )),
      <UI
        type='pointer'
        items={[
          `Является ли представленное вещество нефтепродуктом? Если да, то к какой группе, марке продукции относится и каково его ос­новное назначение?`,
          `Относится ли представленный нефтепродукт к группе горю­че-смазочных материалов? Если да, то к какой группе, марке про­дукции относится и каково его основное назначение?`,
          `Относится ли представленная на исследование жидкость к группе легковоспламеняющихся жидкостей? Если да, то какова ее марка (химическое название)?`,
          `Находился ли смазочный материал в эксплуатации? Если да, то какие посторонние примеси (загрязнения) присутствуют?`,
          `Имеются ли на представленном объекте следы (пятна, нас­лоения) нефтепродуктов, легковоспламеняющихся жидкостей? Ес­ли да, то к какой группе, марке продукции они относятся и како­во их основное назначение?`,
          `Имеют ли представленный образец и образец сравнения об­щий источник происхождения?`,
          `Однородны ли нефтепродукт, обнаруженный на предмете-но­сителе, и нефтепродукт представленный на исследование? `,
          `Какой марке соответствует представленный образец бензина?`,
          `Является ли представленный на экспертизу бензин смесью бензинов различных марок (высокосортного с низкосортным)? Ес­ли да, то каково содержание в смеси бензина более низкого сорта?`,
          `Не содержатся ли в бензине, представленном на исследо­вание, примеси иных нефтепродуктов (керосина, дизельного топ­лива) или других жидкостей (воды)?`,
        ]}
      />,
      ...[
        `Объекты, на которых предполагается наличие нефтепродуктов, упаковывают (запаивают) в полиэтиленовую пленку; жидкие неф­тепродукты помещают в стеклянную тару с притертой стеклянной корковой, пластмассовой (не резиновой) пробкой. Вещества, обна­руженные на асфальте, почве, собираются чистым марлевым там­поном (или вместе с грунтом) и направляются на экспертизу вмес­те с образцами дорожного покрытия или грунта. Пробы бензина отбираются в количестве 100 - 200 мл каждого образца в сухую и чистую стеклянную емкость, герметично закрываемую пробкой. Каждый образец снабжается пояснительной надписью, где по воз­можности указываются его основные характеристики: партия, окта­новое число, фракционный состав, содержание тетраэтилсвинца, завод-изготовитель.`,
        `При отборе образцов бензина, качество которого вызывает сом­нение, по возможности всегда лучше иметь контрольный образец бензина данной марки и партии, который хранится на нефтебазах в течение 45 суток, а на автозаправочных станциях - в течение су­ток. К контрольному образцу всегда  прилагается паспорт, в котором указаны основные характеристики бензина.`,
        `Вопросы, решаемые при исследовании силикатных материалов (стекло, керамика, вяжущие материалы) и изделий из него:`,
      ].map((el) => (
        <Typography sx={{ fontSize: '1.2rem', m: '.5rem 0' }} children={el} />
      )),
      <UI
        type='pointer'
        items={[
          `Относится ли представленный на экспертизу (исследование) объект к силикатным материалам (изделиям из них)? Если да, то к какому классу, типу он относится?`,
          `Является ли представленный на исследование объект оскол­ком фарного рассеивателя? Если да, то на каком транспортном средстве этот рассеиватель мог быть установлен?`,
          `Могли ли представленные на экспертизу (исследование) объекты иметь общий источник происхождения? Какова область применения данного материала?`,
          `Имеются ли на поверхности стекла наслоения (наложения) инородных веществ? Если да, то каковы их природа, возможный источник происхождения?`,
        ]}
      />,
      ...[
        `При изъятии образцов для сравнения нельзя очищать их по­верхность. Образцы, имеющие предположительно различный ис­точник происхождения, должны упаковываться раздельно. Оскол­ки должны быть упакованы таким образом, чтобы обеспечить со­хранность наслоений и неповрежденность поверхности.`,
        `Вопросы, решаемые при исследовании металлов, сплавов и изделий из них:`,
      ].map((el) => (
        <Typography sx={{ fontSize: '1.2rem', m: '.5rem 0' }} children={el} />
      )),
      <UI
        type='pointer'
        items={[
          `Из какого металла (сплава) изготовлен предмет, представ­ленный на экспертизу?`,
          `Какова марка данного металла (сплава)?`,
          `Каковы могут быть назначение или область применения дан­ного металла (сплава)?`,
          `Является ли представленный на исследование металл (сплав) драгоценным? Если да, то каков его (их) вес или количественное содержание в сплаве?`,
          `Имеют ли изделия (частицы) из металла (сплава) общую ро­довую (групповую) принадлежность?`,
          `Имеют ли изделия из металла (сплава) единый источник происхождения?`,
          `Принадлежат ли металлические частицы изделию (объекту, предмету), представленному для исследования?`,
          `Имеется ли на представленном объекте металлическое покрытие? Если да, то каковы его химический состав, назначение?`,
          `Имеются ли на представленном объекте следы металлиза­ции? Если да, то каков может быть источник их происхождения?`,
          `Является ли представленная на исследование монета под­дельной? Если да, то из какого металла (сплава) она изготовлена?`,
          `Имелась ли ранее на представленном объекте какая-либо рельефная маркировка (номер, текст и т. п.)? Если да, то какая именно?`,
          `Подвергалась ли изменению маркировка, имеющаяся на объекте?`,
        ]}
      />,
      ...[
        `Металлические объекты, направляемые на экспертизу, должны быть высушены при комнатной температуре. Недопустимо очищать их поверхность от загрязнений, лакокрасочного покрытия, ржавчины.`,
        `Вопросы, решаемые при исследовании полимерных материалов и изделий из них:`,
      ].map((el) => (
        <Typography sx={{ fontSize: '1.2rem', m: '.5rem 0' }} children={el} />
      )),
      <UI
        type='pointer'
        items={[
          `Является ли данное вещество полимером?`,
          `К какому типу, виду относится полимер?`,
          `Каково назначение данного типа, вида полимера?`,
          `Имеют ли общую родовую (групповую) принадлежность ма­териал, из которого изготовлено данное полимерное изделие, и материалы образцов, представленных для сравнительного иссле­дования?`,
          `Имеют ли образцы полимерных изделий единый источник происхождения?`,
          `Могли ли принадлежать полимерные материалы или частицы на предмете-носителе конкретному изделию?`,
          `Образован ли след на асфальте (одежде потерпевшего и т. д.) частицами резины? Если да, то каковы тип, вид дан­ной резины?`,
          `Какой клеящий материал применяли для склеивания объек­тов?`,
          `Не использовался ли данный клей при склеивании иссле­дуемого объекта?`,
          `Какое из представленных клеящих веществ применялось для склеивания объекта?`,
        ]}
      />,
      ...[
        `Следы от полимерных изделий изымаются вместе с предметом или подложкой. При невозможности изъятия предмета целиком делаются соскобы его поверхности со следами полимера. Для сравнительных исследований лучше представлять полимерное из­делие целиком, либо сделать несколько срезов со всех сторон его составных частей, упаковав их отдельно друг от друга, с поясни­тельными надписями.`,
        `Вопросы, решаемые при исследовании наркотических средств, психотропных, сильнодействующих, ядовитых и одурманивающих веществ:`,
      ].map((el) => (
        <Typography sx={{ fontSize: '1.2rem', m: '.5rem 0' }} children={el} />
      )),
      <UI
        type='pointer'
        items={[
          `Является ли представленное на исследование вещество нар­котическим средством, психотропным веществом или прекурсором? Если да, то каким именно?`,
          `Является ли представленное на исследование вещество силь­нодействующим, ядовитым, одурманивающим веществом? Если да, то каким именно?`,
          `Каково количество наркотического средства (психотропного, сильнодействующего вещества)?`,
          `Имеются ли на предметах, изъятых у подозреваемого сле­ды наркотических средств, психотропных, сильнодействующих, ядовитых веществ? Если да, то каких именно?`,
          `Имеются ли в представленных на исследование смывах с кожных покровов подозреваемого следы наркотических средств, психотропных, сильнодействующих, ядовитых веществ? Если да, то каких именно?`,
          `Имеются ли на поверхности представленных на исследование срезов волос и ногтевых пластин подозреваемого, следы наркотических средств, психотропных, сильнодействующих, ядовитых веществ? Если да, то каких именно?`,
          `Имеются ли в представленных на исследование срезах волос и ногтевых пластин подозреваемого следы наркотических средств, психотропных веществ? Если да, то каких именно?`,
          `Могли ли представленные на исследование наркотические средства (психотропные, сильнодействующие, ядовитые вещества) иметь общий источник происхождения по использованному сырью и технологии изготовления. Могли ли они ранее составлять единую массу?`,
          `Могли ли представленные на исследование наркотические средства растительного происхождения иметь общий источник по месту произрастания сырья, из которого они изготовлены?`,
          `Каким способом могло быть изготовлено или переработано наркотическое средство, психотропное вещество?`,
          `К какому виду относится растение, произрастающее на участке, и является ли оно наркотикосодержащим?`,
        ]}
      />,
      ...[
        `Вопрос, решаемый при исследовании специальных химических веществ:`,
      ].map((el) => (
        <Typography sx={{ fontSize: '1.2rem', m: '.5rem 0' }} children={el} />
      )),
      <UI
        type='pointer'
        items={[
          `Однородно ли по морфологическим признакам, химическому составу красящее вещество, обнаруженное на волосах, кожном покрове, одежде подозреваемого, орудии преступления, иных принадлежащих подозреваемому предметах, с веществом, изъятым на месте происшествия и представленным в качестве образца для сравнительного исследования?`,
        ]}
      />,
      ...[
        `Следы специальных химических веществ на месте происшест­вия изымают ватным тампоном, помещают в полиэтиленовый па­кет, опечатывают и направляют на экспертизу. Следы специаль­ных химических веществ, обнаруженные на грунте, снегу, одежде, изымают вместе с объектом-носителем (либо его фрагментом). На экспертизу также следует направлять образец вещества, ранее приобщенный к акту об установке спецсредства на данном объекте хранения товарно-материальных ценностей.`,
      ].map((el) => (
        <Typography sx={{ fontSize: '1.2rem', m: '.5rem 0' }} children={el} />
      )),
    ],
    img: '',
  },
  {
    title: 'Электротехническая экспертиза',
    hiddenAfterCount: 4,
    collapsed: true,
    description: [
      ...[
        `Судебная электротехническая экспертиза производится в целях изучения работы электросетей и электрооборудования, причин возникновения в них аварийных режимов, работы аппаратов защиты электросетей и проч.`,
        `На разрешение электротехнической экспертизы выносятся в основном следующие вопросы диагностического характера:`,
      ].map((el) => (
        <Typography sx={{ fontSize: '1.2rem', m: '.5rem 0' }} children={el} />
      )),
      <UI
        type='pointer'
        items={[
          `Каковы технические характеристики исследуемых элементов электроустановки, электроприбора?`,
          `Находится ли электроустановка, электроприбор в исправном состоянии? Если нет, то каковы причины неисправностей?`,
          `Возникли ли неисправности в результате нарушения технологии изготовления или неправильной эксплуатации, по иным причинам?`,
          `Соответствует ли сечение электропроводки потребляемой мощности? Каковы характеристики электропроводки, изоляционного покрытия? Соответствует ли электропроводка на объекте требованиям Правил устройства электроустановок (ПУЭ)?`,
          `Соответствует ли характеристика устройства электрозащиты (плавкого предохранителя, автоматического выключателя) требованиям ПУЭ? Каковы характеристики устройства электрозащиты?`,
          `Каковы должны быть токовые нагрузки на кабельные изделия на отдельных участках электросети при штатных режимах работы? Не работали ли отдельные участки электросети в режиме токовой перегрузки? Если да, то какова была ее величина?`,
          `Имеются ли следы изменений, переделок в узлах и деталях устройства электрозащиты, и если имеются, то как это отразилось на его характеристиках?`,
          `В каком состоянии находились на объекте устройства заземления и молниезащиты? Соответствовали ли они ПУЭ?`,
          `Имеются ли на представленных вещественных доказательствах признаки аварийных явлений (оплавления, следы токовой перегрузки, короткого замыкания и т.п.)?`,
          `Каков механизм возникновения и развития аварийного режима работы электроустановки?`,
          `Сработало ли устройство электрозащиты при аварийном режиме электроустановки, а если не сработало, то чем это вызвано?`,
          `Чем вызвано разрушение плавкой вставки предохранителя? Из какого материала выполнена плавкая вставка предохранителя? Является ли плавкая вставка стандартной или нестандартной (самодельной), какова ее защитная характеристика?`,
          `Находилась ли лампа накаливания в момент разрушения в исправном состоянии или ее спираль перегорела ранее? Произошло ли повреждение колбы лампы накаливания, когда она находилась во включенном или в выключенном состоянии?`,
          `Позволяла ли данная схема подключения нагрузки пользоваться электроэнергией в обход электросчетчика?`,
        ]}
      />,
      ...[
        `Объектами электротехнической экспертизы являются электрооборудование, электроприборы, их части, фрагменты электропроводов и кабелей, устройства электрозащиты (плавкие предохранители, автоматические выключатели и проч.), электрокоммутирующие устройства и т.п.`,
        `В качестве сравнительных образцов при назначении электротехнических экспертиз представляются аналогичные приборы и оборудование, отрезки проводов и кабелей, предохранители, аппараты защиты и др.`,
      ].map((el) => (
        <Typography sx={{ fontSize: '1.2rem', m: '.5rem 0' }} children={el} />
      )),
    ],
    img: '',
  },
];
