import {ControlType} from "../input/const/control-type.const";
import {InputLabel} from "@mui/material";
import Input from "../input/Input";
import React from "react";
import {FormControl as FormControlMUI} from '@mui/material';
import PropTypes from "prop-types";


const FormControl = (props) => {
    const {fCSx, iFx, controlName, controlType, label, validators, onBlur, variant, value} = props;
    return <FormControlMUI variant={variant} sx={fCSx} key={controlName}>
        {
            controlType === ControlType.input && <InputLabel htmlFor={controlName}>
                {label}
            </InputLabel>
        }
        <Input
            sx={iFx}
            label={label}
            id={controlName}
            validators={validators}
            onBlur={onBlur}
            controlType={controlType}
            defaultValue={value}
        />
    </FormControlMUI>
}
FormControl.propTypes = {
    fCSx: PropTypes.object,
    iFx: PropTypes.object,
    controlName: PropTypes.string,
    controlType: PropTypes.string,
    label: PropTypes.string,
    validators: PropTypes.array,
    onBlur: PropTypes.func,
    variant: PropTypes.string,
    value: PropTypes.string | PropTypes.number,
}
export default FormControl;
