import Logo from '../../assets/images/logo-symbol.png'
import Erevan1 from '../../assets/images/news/erevan/1.jpg'
import Erevan2 from '../../assets/images/news/erevan/2.jpg'
import Erevan3 from '../../assets/images/news/erevan/3.jpg'
import Erevan4 from '../../assets/images/news/erevan/4.jpg'
import Erevan5 from '../../assets/images/news/erevan/5.jpg'
import Erevan6 from '../../assets/images/news/erevan/6.jpg'
import Erevan7 from '../../assets/images/news/erevan/7.jpg'
import Erevan8 from '../../assets/images/news/erevan/8.jpg'

export const NewsList = [
    {
        tagName: 'Новость',
        text: 'Сотрудниками «Байкальского центра судебных экспертиз и графоанализа»\n' +
            'одержана очередная победа.\n' +
            'Нерюнгринским городским судом в АНО «БЦСЭиГ» была назначена судебная\n' +
            'техническая экспертиза документов. В соответствии с определением суда по материалам\n' +
            'гражданского дела была произведена экспертиза, которая вместе со четом на оплату была\n' +
            'направлена в адрес суда. В связи с примирением сторон Нерюнгринским городским судом\n' +
            'экспертной организации было отказано в оплате экспертизы.\n' +
            'Генеральным директором АНО «БЦСЭиГ» на определение суда первой\n' +
            'инстанции в адрес Верховного суда Республики Саха (Якутия) направлена частная\n' +
            'жалоба, которая решением Верховного суда удовлетворена в полном объеме, решение\n' +
            'суда первой инстанции отменено полноностью. На инициатора назначения экспертизы\n' +
            'возложена оплата по исполнительному листу.',
        images: [Logo],
    },
    {
        tagName: 'Новость',
        text: '16 апреля 2022 года генеральный директор БЦСЭ и Г Орловская Рита Константиновна приняла участие во II региональной научно-практической конференции',
        images: [Logo]
    },
    {
        tagName: 'Новость',
        text: '10 августа 2021 года Орловская Рита Константиновна вступила в должность генерального директора БЦСЭ и Г',
        images: [Logo]
    },
    {
        tagName: 'Новость',
        text: '03 июля 2021 года ушёл из жизни замечательный человек, основатель Байкальского центра судебных экспертиз и графоанализа - (генеральный директор)  Шестеперова Елена Лукинична.',
        images: [Logo]
    },
    {
        tagName: 'Новость',
        text: 'С 25 по 27 сентября 2019 года в Ереване (Армения) генеральный директор БЦСЭиГ Шестеперова Е.Л. приняла участие в международной научно-практической конференции',
        images: [Erevan1, Erevan2, Erevan3, Erevan4, Erevan5, Erevan6, Erevan7, Erevan8]
    },
    {
        tagName: 'Новость',
        text: '01 июля 2019 года, была опубликована совместная статья Бубновой И.С. и Шестеперовой Е.Л. в научном журнале "Глаголъ правосудия"',
        images: [Logo]
    }
]
