import React, {memo, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { Button, Link, Tab, Tabs } from '@mui/material';
import { Routes } from '../../const/routes';

import styles from './Footer.module.css';
import logo from '../../../assets/images/logo-symbol.png';
import classNames from 'classnames';

const Footer = (props) => {
  return (
    <div className={classNames(styles.container, 'container')}>
      <div className={styles.infoRow}>
        <img className={styles.logo} alt='Logo' src={logo}></img>
        <div className={styles.contacts}>
          <p className={styles.title}>Контакты:</p>
          <p className={styles.item}>+7 (950) 059-24-04</p>
          <p className={styles.item}>+7 (3952) 35-76-90</p>
          <a className={styles.item} href='mailto:baykalskiytsentr@list.ru'>
            baykalskiytsentr@list.ru
          </a>
        </div>
        <div className={styles.addres}>
          <p className={styles.title}>Адрес:</p>
          <p className={styles.item}>
            г. Иркутск, ул. Байкальская, 291, офис 808
          </p>
        </div>
      </div>
      <div className={styles.linksRow}>
        <p className={styles.name}> АНО "БЦСЭИГ" </p>
        <div className={styles.links}>
          <Link></Link>
          <Link></Link>
        </div>
      </div>
    </div>
  );
};

Footer.propTypes = {};

export default memo(Footer);
