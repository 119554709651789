import React from 'react';
import PropTypes from 'prop-types';

import styles from './Info.module.css';
import { ExpertiseList } from './const/expertise-list.const';
import Expertise from './components/Expertise/Expertise';
import classNames from 'classnames';
import {memoize} from "lodash/function";

function Info(props) {
  
  return (
    <div className='container'>
      <p className='title'>Проводимые исследования</p>
      <div className={classNames('block', styles.items)}>
        {
          ExpertiseList.map((exp, i) => <Expertise {...exp} key={exp.title}/>)
        }
      </div>
    </div>
  );
}

Info.propTypes = {};

export default memoize(Info);
