import React, {memo, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useNavigate, useLocation, useParams} from 'react-router-dom';
import {Button, Link, Tab, Tabs} from '@mui/material';
import {Routes} from '../../const/routes';

import './Header.css';
import logo from '../../../assets/images/logo-symbol.jpg';

const routesPaths = Routes.map((route) => route.path);
const Header = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [tabValue, setTabValue] = useState(location.pathname);
    const param = useParams();

    const handleChange = (_, value) => {
        setTabValue(value);
    };

    const navigateTo = (path) => {
        window.scrollTo({top: 0, behavior: 'smooth',})
        navigate(path);
    }

    useEffect(() => {
        if (tabValue === null) return;
        const pathElements = tabValue.split('/');
        const isCorrectPath = !!routesPaths.filter((path) =>
            pathElements.some((el) => path.includes(el))
        ).length;
        if (isCorrectPath) navigateTo(tabValue);
        else navigateTo('/');
    }, [tabValue]);

    useEffect(() => {
        const currentPath = location.pathname.split('/').slice(1,-1)[0];
        const paths = Routes.filter((el) => !el.hidden)
        const isCorrectPath = paths
            .map((route) => route.path.split('/').slice(1,2)[0])
            .includes(currentPath)
        if (isCorrectPath) return;
        setTabValue(null);
    }, [param])

    const getTabs = function () {
        return Routes.filter((el) => !el.hidden).map((page) => (
            <Tab value={page.path} label={page.title} key={page.path} wrapped/>
        ));
    }

    return (
        <div className='header'>
            <div className='left-part'>
                <img className='logo' alt='Logo' src={logo}></img>
                <div className='title-container'>
                    <p className='title'>
                        Байкальский центр судебных экспертиз и графоанализа
                    </p>
                    <p className='sub-title'>Автономная некоммерческая организация</p>
                </div>
            </div>
            <div className='midle-part'>
                <Tabs
                    value={tabValue}
                    onChange={handleChange}
                    aria-label='wrapped label tabs example'
                >
                    {getTabs()}
                </Tabs>
            </div>
            <div className='right-part'>
                <Button onClick={() => handleChange(null, 'order/judicial')}>
                    Заказать экспертизу
                </Button>
            </div>
        </div>
    );
};

Header.propTypes = {};

export default Header;
