import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack5';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import Pdf from '../../../assets/doc/presentation.pdf';
import styles from './Presentation.module.css';

import { minHeight } from '@mui/system';
import Modal from '../modal/Modal';
import Button from '../button/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { blue } from '@mui/material/colors';
import classNames from 'classnames';

const url = `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`
pdfjs.GlobalWorkerOptions.workerSrc = url

const Presentation = (prop) => {
  const [isOpen, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const handleClose = () => {
    setShowModal(false);
    setPageNumber(1);
  }
  const handleOpen = () => setShowModal(true);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };
  const onRenderedSuccess = () => {
    setTimeout(() => setIsLoading(false), 250);
  };

  const goToPrevPage = () => {
    setIsLoading(true);
    setTimeout(
      () => setPageNumber(pageNumber - 1 <= 1 ? 1 : pageNumber - 1),
      250
    );
  };

  const goToNextPage = () => {
    setIsLoading(true);
    setTimeout(() => {
      setPageNumber(
        numPages && pageNumber + 1 >= numPages
          ? Number(numPages)
          : pageNumber + 1
      );
    }, 250);
  };
  return (
    <div className={styles.container}>
      <Modal
        isOpen={isOpen}
        handleClose={handleClose}
        minHeight='45rem'
        minWidth='55rem'
      >
        <div>
          <nav className={styles.controls}>
            <Button type='oval' callback={goToPrevPage}>
              Назад
            </Button>
            <p>
              Страница {pageNumber} из {numPages}
            </p>
            <Button type='oval' callback={goToNextPage}>
              Вперед
            </Button>
          </nav>
          <Document
            file={Pdf}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            <div
              className={classNames(styles.pageContainer, {
                [styles.isLoading]: isLoading,
              })}
            >
              <Page
                className={styles.page}
                renderAnnotationLayer={false}
                pageNumber={pageNumber}
                onRenderSuccess={onRenderedSuccess}
              />
            </div>
          </Document>
        </div>
      </Modal>
      <Button type='oval' callback={handleOpen}>
        Смотреть презентацию
      </Button>
    </div>
  );
};

Presentation.propTypes = {
  // isOpen: PropTypes.bool.isRequired,
  // handleClose: PropTypes.func.isRequired,
};

export default Presentation;
