import Box from "@mui/material/Box";
import classNames from "classnames";
import React, { memo } from "react";
import styles from "./Contacts.module.css";
import Map from "./components/map/Map";

const Contacts = (props) => {
  const divBlock = (title, data, sx) => (
    <Box sx={sx} className={styles.block}>
      <p className={styles.title}>{title}:</p>
      <p className={styles.data}>{data}</p>
    </Box>
  );

  return (
    <div className={classNames(styles.container, "container")}>
      <div className={styles.contacts}>
        <Map />
        <div className={styles.contactsData}>
          <p className="title">Наши Контакты</p>
          <div className={styles.contactItem}>
            {divBlock(
              "генеральный директор",
              "Орловская Рита Константиновна",
              {}
            )}
            {divBlock("e-mail", "baykalskiytsentr@list.ru", {})}
            {divBlock("тел.", "+7 (950) 059-24-04", {})}
          </div>
          <div className={styles.line}></div>
          <div className={styles.contactItem}>
            {divBlock("секретарь", "кузьменцова галина юрьевна", {})}
            {divBlock("тел.", "+7 (3952) 35-76-90", {})}
          </div>
          <div className={styles.line}></div>
          <div className={styles.contactItem}>
            {divBlock(
              "Адрес",
              "664050, Россия, г. Иркутск, ул. Байкальская, 291, офис 808",
              {}
            )}
            {divBlock("тел.", "+7 (3952) 35-76-90", {})}
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(Contacts);
