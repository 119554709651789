import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './UI.module.css';

const Ul = (props) => {
  const {items, type='none'}= props;

  const ulClasses = classNames(
    styles.simpleList,
    {
      [styles.pointer]: type ==='pointer',
      [styles.numeric]: type ==='numeric',
    }
  );

  return (
    <ul className={ulClasses}>
      {items.map((item, i) => (<li className={styles.item} key={i}>{item}</li>))}
    </ul>
  );
}

Ul.propTypes = {
  items: PropTypes.array,
  type: PropTypes.string,
}

export default Ul;
