import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './Expertise.module.css';
import Tooltip from '../../../../shared/component/tooltip/Tooltip';
import AnimateHeight from 'react-animate-height';

function Expertise({ title, hiddenAfterCount, collapsed, description }) {
  const [collapse, setCollapse] = useState(collapsed);

  const collapseHandler = (event) => {
    setCollapse(!collapse);
  };
  const myRef = useRef(null);
  const scrollToEl = (height) => {
    if (height == 0) {
      window.scrollTo({
        top: myRef.current.offsetTop-100,
        behavior: 'smooth',
      });
    }
  };

  return (
      <Tooltip reff={myRef}>
        <div className={styles.item} onClick={collapseHandler}>
          <p
            className={classNames('title sm', 'light-blue', styles.title)}
          >
            {title}
          </p>
          <AnimateHeight
            height={collapse ? 0 : 'auto'}
            duration={1500}
            onHeightAnimationStart={scrollToEl}
          >
            <div>{description}</div>
          </AnimateHeight>
        </div>
      </Tooltip>
  );
}

Expertise.propTypes = {};

export default Expertise;
