import React from 'react';
import PropTypes from 'prop-types';

import {Backdrop, Fade, IconButton, Modal as ModalMUI} from '@mui/material';
import Box from '@mui/material/Box';
import styles from "./Modal.module.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faXmark} from "@fortawesome/free-solid-svg-icons/faXmark";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    border: 0,
    outline: 'none',
};

const Modal = ({
                   isOpen, handleClose, top = '50%', bottom, left = '50%', right, children, sx, boxSx,
               }) => {
    return (<ModalMUI
        sx={{sx}}
        open={isOpen}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
        closeAfterTransition
        componentsProps={{
            backdrop: {
                timeout: 500,
            },
            Backdrop: {Backdrop}
        }}
    >
        <Fade in={isOpen}>
            <Box sx={{...style, top, bottom, left, right, ...boxSx}}>
                {children}
                <div className={styles.close}>
                    <IconButton aria-label="Close" onClick={handleClose}>
                        <FontAwesomeIcon icon={faXmark}/>
                    </IconButton>
                </div>
            </Box>
        </Fade>
    </ModalMUI>);
}

Modal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    top: PropTypes.string,
    bottom: PropTypes.string,
    left: PropTypes.string,
    right: PropTypes.string,
    children: PropTypes.element.isRequired,
    boxSx: PropTypes.object,
};

export default Modal;
