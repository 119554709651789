import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import Button from '../button/Button'
import {ButtonType} from '../button/modal'
import classNames from 'classnames'
import styles from './News.module.css';
import Modal from "../modal/Modal";
import {ImageCarousel} from "../image-carousel/ImageCarousel";


const News = (props) => {
    const {images, text, tagName} = props;
    const [isOpen, setShowModal] = useState(false);
    const handleClose = () => {
        setShowModal(false);
    }
    return (
        <>
            <Modal
                isOpen={isOpen}
                handleClose={handleClose}
                boxSx={{width: '30rem', height: '40rem'}}
            >
                <div className={classNames('block', styles.modalContent)}>
                    <ImageCarousel autoPlay={true} items={images.map(image => ({image}))} sx={{flex: '1'}}/>
                    <p className={classNames(styles.modalText)}>
                        {text}
                    </p>
                </div>
            </Modal>
            <div className={styles.news}>
                <img className={styles.image} src={images[0]} alt={'Not Found'} onClick={() => setShowModal(true)}/>
                <div className={classNames(styles['text-part'], !!images ? styles['with-image'] : '')}>
                    <div className={styles.tag}>
                        <p>{tagName}</p>
                    </div>
                    <p className={styles.text}>{text}</p>
                    <Button type={ButtonType.oval} callback={() => setShowModal(true)}>Узнать больше</Button>
                </div>
            </div>
        </>
    );
}

News.propsType = {
    images: PropTypes.string,
    text: PropTypes.string,
    tagName: PropTypes.string,
}

export default News;
