import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useNavigate, useParams} from 'react-router-dom';
import emailjs from 'emailjs-com';
import FormGroup from '@mui/material/FormGroup';
import {InputLabel, ToggleButton, ToggleButtonGroup} from '@mui/material';
import Typography from '@mui/material/Typography';

import Input from '../../shared/component/input/Input';
import JudicialImg from '../../assets/images/orders/judicial.jpg';
import UnjudicialImg from '../../assets/images/orders/unjudicial.jpg';

import {FormContent, Titles} from './const/text.const';
import styles from './Order.module.css'
import Button from "../../shared/component/button/Button";
import {ButtonType} from "../../shared/component/button/modal";
import {memoize} from "lodash/function";
import Modal from "../../shared/component/modal/Modal";
import {ControlType} from "../../shared/component/input/const/control-type.const";
import FormControl from "../../shared/component/form-control/FormControl";

const RouteParams = {
    unjudicial: 'unjudicial', judicial: 'judicial',
};

const formState = new Map();

const Order = () => {
    emailjs.init('b-jvf28BnaIEM9Iii');
    const param = useParams();
    const navigate = useNavigate();
    const [mode, setMode] = useState(RouteParams.unjudicial);
    const [modalIsOpen, setModalState] = useState(false);
    const [modalText, setModalText] = useState('');
    useEffect(() => {
        const {id} = param;
        const currentMode = RouteParams[id] || RouteParams.judicial;
        formState.clear();
        FormContent[currentMode].forEach(control => {
            formState.set(control.controlName, {name: control.controlName, value: '', valid: true, touched: false});
        });
        setMode(currentMode);
    }, [param]);

    const formChanged = (controlName, state) => {
        // console.log(controlName, state);
        const control = formState.get(controlName);
        control.value = state.value;
        control.valid = state.valid;
        control.touched = state.touched;
    };

    const handleChangeExpertiseToggle = (event) => {
        navigate(`/order/${RouteParams[event.target.value] || RouteParams.judicial}`);
    }

    const sendForm = () => {
        // TODO Validate form before sending
        // for(let value of formState.values()) {
        //     if(!value.valid || !value.touched) {
        //         console.log('InValid');
        //         return;
        //     }
        // }
        let templateParams = {date: new Date().toLocaleDateString()};
        let templateId = 'template_x37of4d';
        for (let key of formState.keys()) {
            templateParams[key] = formState.get(key).value;
        }
        if (mode === RouteParams.unjudicial) {
            templateId = 'template_908d5yg'
        }

        emailjs.send('service_qrf2u0m', templateId, templateParams)
            .then(function (response) {
                setModalText('Заявление отправлено успешно');
                setModalState(true);
                console.log('SUCCESS!', response.status, response.text, templateParams);
            }, function (error) {
                setModalText('Извините. Произошла ошибка.');
                setModalState(true);
                console.log('FAILED...', error);
            }).then(() => {
            setTimeout(() => setModalState(false), 30000)
        });
    }

    const generateFormControl = ({label, validators, controlName, controlType}) => {
        return <FormControl
            variant={'outlined'}
            value={''}
            fCSx={{margin: '.5rem'}}
            key={controlName}
            controlType={controlType}
            controlName={controlName}
            label={label}
            validators={validators}
            onBlur={state => formChanged(controlName, state)}
            variant={'outlined'}
            iSx={{
                background: 'white',
                fontSize: '1.2rem',
                height: controlType === ControlType.input ? '3rem' : 'auto'
            }}
        />
    }

    const getTitle = () => {
        const title = 'Вы можете направить заявление прямо сейчас путем заполнения нижеприведенной анкеты.';
        const subTitle = mode === RouteParams.judicial ?
            'На ваш адрес будет нправлен пакет документов для предоставления в суд.'
            : 'С вами свяжется наш специалист для заключения договора на выполнение исследования.';
        return title + ' ' + subTitle;
    }

    const generateForm = () => {

        return (<FormGroup sx={{flex: 1}}>
            <>
                <Typography
                    sx={{fontSize: '1.2rem', m: '2rem 0 .5rem'}}
                    children={getTitle()}
                />
                {FormContent[mode].map((el) => generateFormControl(el))}
                <Button
                    sx={{
                        width: '15rem', margin: '1rem auto',
                    }}
                    callback={sendForm}
                    children='Отправить'
                />
            </>
        </FormGroup>)

    }

    return (
        <div className='container'>
            <div className={styles.toggleContainer}>
                <ToggleButtonGroup
                    color="primary"
                    value={mode}
                    exclusive
                    onChange={handleChangeExpertiseToggle}
                    aria-label="Platform"
                >
                    <ToggleButton value={RouteParams.judicial}>Судебная экспертиза</ToggleButton>
                    <ToggleButton value={RouteParams.unjudicial}>Внесудебное исследование</ToggleButton>
                </ToggleButtonGroup>
            </div>
            <Typography
                sx={{fontSize: '1.4rem', m: '.5rem 0 3rem', textAlign: 'center'}}
                children={mode === RouteParams.judicial ? Titles.judicial : Titles.unjudicial}
            />
            <div className={styles.formContainer}>
                {generateForm()}
                <img src={mode === RouteParams.judicial ? JudicialImg : UnjudicialImg} alt={'Not Found'}/>
            </div>

            <Modal
                minHeight='5rem'
                minWidth='10rem'
                isOpen={modalIsOpen}
                handleClose={() => setModalState(false)}
                sx={{border: 'none'}}
            >
                <Typography
                    sx={{fontSize: '1.4rem', m: '.5rem 0 3rem', textAlign: 'center'}}
                    children={modalText}
                />
            </Modal>
        </div>);
};

Order.propTypes = {};

export default Order;
