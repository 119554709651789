import React, {memo} from 'react';
import {Map as YMap, Placemark, YMaps} from "react-yandex-maps";

export const Map = () => {
    return <div style={{width: '65%', height: '100%'}}>
        <YMaps>
            <YMap defaultState={{center: [52.2592148, 104.3572533], zoom: 16}} width='100%' height='30rem'>
                <Placemark
                    geometry={[52.2592148, 104.3572533]}
                    options={
                        {
                            preset: 'islands#dotIcon', // список темплейтов на сайте яндекса
                            iconColor: 'green', // цвет иконки, можно также задавать в hex
                        }}
                />
            </YMap>
        </YMaps>
    </div>
};
export default memo(Map);
