import React from 'react';
import PropTypes from 'prop-types';
import {
    Avatar,
    Card as CardMui,
    CardContent,
    Link,
    Typography,
} from '@mui/material';
import {Box} from '@mui/system';

import styles from './Card.module.css';

function Card(props) {
    const {type} = props;

    const userCard = () => {
        const {
            avatar,
            textLevel,
            fullName,
            description,
            backgroundColor = 'white',
            boxHeight='20rem',
            boxWidth='35rem'
        } = props;
        return (
            <Box
                height={boxHeight}
                width={boxWidth}
                sx={{backgroundColor: backgroundColor}}
            >
                <CardMui sx={{height: '100%', width: '100%', borderRadius: '.5rem'}}>
                    <CardContent classes={{root: styles.content}}>
                        <Avatar
                            sx={{width: '16rem', height: '16rem'}}
                            classes={{root: styles.avatar}}
                            src={avatar}
                            alt={fullName}
                        />
                        <div className={styles.textContainer}>
                            {
                                textLevel &&
                                <Typography
                                    sx={{fontSize: '.8rem', fontWeight: 600}}
                                    classes={{root: styles.level}}
                                >
                                    {textLevel}
                                </Typography>}
                            {
                                fullName &&
                                <Typography
                                    sx={{fontSize: '1.8rem', mt: '1rem'}}
                                    classes={{root: styles.fullName}}
                                >
                                    {fullName}
                                </Typography>}
                            {
                                description &&
                                <Typography
                                    sx={{fontSize: '1rem', mt: '2rem'}}
                                    classes={{root: styles.description}}
                                >
                                    {description}
                                </Typography>}
                        </div>
                    </CardContent>
                </CardMui>
            </Box>
        );
    };

    const text = () => {
        const {
            link,
            linkText = 'узнать больше',
            text,
            backgroundColor = 'white',
        } = props;

        return (
            <Box
                height='15rem'
                width='25rem'
                padding='2rem'
                sx={{
                    backgroundColor: backgroundColor,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'start',
                    justifyContent: 'space-between',
                    boxSizing: 'border-box',
                    borderRadius: '.8rem',
                }}
            >
                <Typography sx={{fontSize: '1.6em', fontWeight: 600, m: 'auto 0'}}>
                    {text}
                </Typography>
                <Link href={link} target='_blank' rel='noopener'>
                    {linkText}
                </Link>
            </Box>
        );
    };

    switch (type) {
        case 'text':
            return text();
            break;
        case 'media':
            return <></>;
            break;
        case 'userCard':
            return userCard();
            break;
        default:
            return userCard();
    }
}

Card.propTypes = {
    avatar: PropTypes.string,
    textLevel: PropTypes.string,
    fullName: PropTypes.string,
    description: PropTypes.string,
    type: PropTypes.string,
    backgroundColor: PropTypes.string,
};

export default Card;
