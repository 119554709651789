import React from 'react';
import { blue } from '@mui/material/colors';
import { useNavigate } from 'react-router-dom';

import './MainPage.css';

import Tooltip from '../../shared/component/tooltip/Tooltip';
import Law from '../../assets/images/law.jpg';
import UnLaw from '../../assets/images/unlaw.jpg';
import Icon from '../../shared/component/icon/Icon';
import { NewsList } from '../../shared/const/news';
import News from '../../shared/component/news/News';
import Button from '../../shared/component/button/Button';
import Presentation from '../../shared/component/presentation/Presentation';

const customStyles = {
  height: '30rem',
  position: 'relative',
  overflow: 'hidden',
  borderRadius: '.5rem',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'auto 100%',
  zIndex: 1,
  boxSizing: 'border-box',
  padding: '2rem',
  flexBasis: '50%',
  maxWidth: '45rem',
  cursor: 'pointer',
  margin: '0 1rem',
  flex: '1',
};

const getBackGround = (Img) => `url(${Img})`;


const MainPage = (props) => {
  const navigate = useNavigate();

  const orderList = () => {
    const orderListItems = [
      {
        text: 'Процессуальное действие, состоящее из проведения экспертных исследований и дачи заключения эксперта, в целях установления обстоятельств, подлежащих доказыванию по конкретному делу.',
        title: 'Судебная экспертиза',
        btnTitle: 'Заказать судебную экспертизу',
        customStyles: { ...customStyles, backgroundImage: getBackGround(Law) },
        clickHandle: () => navigate('/order/judicial'),
      },
      {
        text: 'Исследование, которое проводится экспертным учреждением на основании заключенного договора на оказание экспертной услуги между экспертным учреждением и Заказчиком исследования (физическое или юридическое лицо).',
        title: 'Внесудебное исследование',
        btnTitle: 'Заказать внесудебное исследование',
        customStyles: { ...customStyles, backgroundImage: getBackGround(UnLaw) },
        clickHandle: () => navigate('/order/unjudicial'),
      },
    ];
    return (
      <>
        <div className='order-list container'>
          <p className='title'>Закажите исследование</p>
          <div className='items'>
            {orderListItems.map((item, i) => (
              <Tooltip
                type='button'
                customStyles={item.customStyles}
                key={i}
                callback={item.clickHandle}
              >
                <div className='item'>
                  <div className='item-text'>
                    <p className='order-item__title'>
                      {item.title}
                    </p>
                    <p className='item-description'>{item.text}</p>
                  </div>
                  <div className='item-btn'>
                    <Button callback={item.clickHandle}>{item.btnTitle}</Button>
                  </div>
                </div>
              </Tooltip>
            ))}
          </div>
        </div>
      </>
    );
  };

  const guarantees = () => {
    const guaranteesItems = [
      {
        text: 'Индивидуальный подход в каждом конкретном случае',
        icon: 'work',
      },
      {
        text: 'Проведение экспертиз и исследований высококвалифицированными экспертами и специалистами на самом высоком профессиональном уровне',
        icon: 'verified_user',
      },
      {
        text: 'Использование самых современных достижений науки и техники, с соблюдением всех норм закона',
        icon: 'book',
      },
    ];
    return (
      <div className='guarantees container'>
        <span className='title'>Мы гарантируем</span>
        <div className='items'>
          {guaranteesItems.map((item, i) => (
            <div className='item' key={i}>
              <Icon icon={item.icon} color={blue[500]} />
              <hr />
              <p className='text'>{item.text}</p>
            </div>
          ))}
        </div>
      </div>
    );
  };


  const aboutUs = () => {
    return (
      <div className='about container'>
        <div className='items'>
          <div className='item'>
            <span className='title sm'>НАША ДЕЯТЕЛЬНОСТЬ</span>
            <p className='text'>
              БЦСЭиГ специализируется на проведении повторных, комиссионных и
              комплексных экспертиз, требующих углубленных теоретических знаний
              и многолетнего практического опыта по всем направлениям.
            </p>
            <p className='text'>
              Также БЦСЭиГ осуществляет консультативную помощь физическим лицам
              и методическую помощь юридическим лицам по вопросам назначения
              экспертиз: отбора сравнительных образцов, постановки вопросов,
              проведение занятий и т.д.
            </p>
            <p className='text'>
              В целях обеспечения проведения надлежащей проверки достоверности
              фактов, БЦСЭиГ обеспечивает участие специалистов с выездом на
              места по основным направлениям деятельности.
            </p>
          </div>

          <div className='item'>
            <Presentation />
          </div>
        </div>
      </div>
    );
  };

  const news = () => {
    return (
      <div className='news container'>
        <span className='title'>Новости</span>
        <div className='items'>
          {NewsList.map((news, i) => (
            <News {...news} key={i}/>
          ))}
        </div>
        {/*<Button>Архив новостей</Button>*/}
      </div>
    );
  };

  return (
    <div className='main-info'>
      {orderList()}
      {guarantees()}
      {aboutUs()}
      {/* {expertiseList()} */}
      {news()}
    </div>
  );
};

export default MainPage;
