import React from 'react';
import About from '../../pages/about/About';
import Info from '../../pages/info/Info';
import MainPage from '../../pages/main-page/MainPage';
import Order from '../../pages/order/Order';
import ContactsWrapper from "../../pages/contacts/ContactsWraper";

export const Routes = [
  {
    title: 'Главная',
    path: '/',
    hidden: false,
    component: <MainPage/>
  },
  {
    title: 'О нас',
    path: '/about',
    hidden: false,
    component: <About/>
  },
  {
    title: 'Информация для заказчика',
    path: '/info',
    hidden: false,
    component: <Info/>
  },
  {
    title: 'Контакты',
    path: '/contacts',
    hidden: false,
    component: <ContactsWrapper/>,
  },
  {
    title: 'Заказать экспертизу',
    path: '/order/:id',
    hidden: true,
    component: <Order/>,
  },
];
