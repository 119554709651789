import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import styles from './Tooltip.module.css';
import classNames from "classnames";

const Tooltip = ({ customStyles, reff, type = 'button', callback, children }) => {
  return (
    <div ref={reff} className={classNames(styles.tooltip, 'tooltip')} style={customStyles} onClick={callback}>
      {type === 'button' ? children : ''}
    </div>
  );
};
Tooltip.propTypes = {
  children: PropTypes.node,
  type: PropTypes.string,
  customStyles: PropTypes.object,
  onClick: PropTypes.func,
  reff: PropTypes.any,
};

export default Tooltip;
