import {ControlType} from "../../../shared/component/input/const/control-type.const";

export const Titles = {
    judicial: `Для того, чтобы заказать экспертизу в БЦСЭиГ Вам необходимо направить заявление на предоставление согласия для проведения экспертизы.`,
    unjudicial: `Для того, чтобы заказать внесудебное исследование в БЦСЭиГ Вам необходимо направить заявление на проведение внесудебного исследования:`,
};
export const FormContent = {
    judicial: [
        {
            label: 'Эл. почта',
            validators: [], //[new RegExp(/[a-z|A-Z|0-9]{1,}@[a-z]{1,}.[a-z]{1,4}$/, 'g'),],
            controlName: 'email',
            controlType: ControlType.input,
        },
        {
            label: 'ФИО',
            validators: [], //[new RegExp(/^([A-Za-z]{1,} ){2}([A-Za-z]{1,})$/, 'g')],
            controlName: 'fullName',
            controlType: ControlType.input,
        },
        {
            label: 'Тел.',
            validators: [], //new RegExp(/^[0-9]{11}$/, 'g')
            controlName: 'phone',
            controlType: ControlType.input,
        },
        {
            label: 'Суд',
            validators: [],
            controlName: 'jud',
            controlType: ControlType.input,
        },
        {
            label: 'Судья',
            validators: [], //[new RegExp(/^([A-Za-z]{1,} ){2}([A-Za-z]{1,})$/, 'g')],
            controlName: 'judge',
            controlType: ControlType.input,
        },
        {
            label: 'Номер дела',
            validators: [],
            controlName: 'stuffNo',
            controlType: ControlType.input,
        },
        {
            label: 'Текст заявления',
            validators: [],
            controlName: 'orderText',
            controlType: ControlType.text,
        },
    ],
    unjudicial: [
        {
            label: 'Эл. почта',
            validators: [], //[new RegExp(/[a-z|A-Z|0-9]{1,}@[a-z]{1,}.[a-z]{1,4}$/, 'g')],
            controlName: 'email',
            controlType: ControlType.input,
        },
        {
            label: 'ФИО',
            validators: [], //[new RegExp(/^([A-Za-z]{1,} ){2}([A-Za-z]{1,})$/, 'g')],
            controlName: 'fullName',
            controlType: ControlType.input,
        },
        {
            label: 'Тел.',
            validators: [], //new RegExp(/^[0-9]{11}$/, 'g')
            controlName: 'phone',
            controlType: ControlType.input,
        },
        {
            label: 'Исследование',
            validators: [],
            controlName: 'searchName',
            controlType: ControlType.input,
        },
        {
            label: 'Текст заявления',
            validators: [],
            controlName: 'orderText',
            controlType: ControlType.text,
        },
    ],
}
