import React, {useEffect, useState} from 'react';
import InputUI from '@mui/material/OutlinedInput';
import PropTypes from 'prop-types';
import {useRef} from 'react';
import {TextField} from "@mui/material";
import {ControlType} from "./const/control-type.const";

const Input = (props) => {
    const {
        validators = [],
        placeholder,
        defaultValue = '',
        onBlur,
        onChange = () => {
        },
        errorConfig = {showError: true},
        label = '',
        sx,
        controlType = ControlType.input,
        rows = 4,
        id,
    } = props;

    const [hasError, setError] = useState(false);
    const [value, setValue] = useState(defaultValue);
    const inputRef = useRef(null);

    const handleChange = (event) => {
        const text = event.target.value;
        setValue(() => text);
        if (!validators.length || validators.every((el) => text.match(el))) {
            onChange({value: text, valid: true, touched: true});
        } else {
            onChange({value: text, valid: false, touched: true});
        }
    };

    useEffect(() => {
        if (inputRef && inputRef.current) {
            inputRef.current.onblur = () => {
                // if (value === defaultValue) return;
                if (!validators.length || validators.every((el) => value.match(el))) {
                    setError(() => false);
                    onBlur({value: value, valid: true, touched: true});
                } else {
                    setError(() => true);
                    onBlur({value: value, valid: false, touched: true});
                }
            };
        }
    });
    return (
        <>
            {
                controlType === ControlType.input ?
                    <InputUI
                        sx={sx}
                        label={label}
                        inputRef={inputRef}
                        onChange={handleChange}
                        placeholder={placeholder}
                        defaultValue={value}
                        error={hasError}
                        id
                    />
                    : <TextField
                        id="outlined-multiline-static"
                        sx={sx}
                        label={label}
                        // placeholder={placeholder}
                        inputRef={inputRef}
                        onChange={handleChange}
                        multiline
                        rows={rows}
                        defaultValue={value}
                        id
                    />
            }
        </>
    );
};

Input.propTypes = {
    onChange: PropTypes.func,
    validators: PropTypes.arrayOf(PropTypes.object),
    placeholder: PropTypes.string,
    defaultValue: PropTypes.oneOf([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
    ]),
    errorConfig: PropTypes.objectOf(PropTypes.bool),
    onBlur: PropTypes.func,
    label: PropTypes.string,
    sx: PropTypes.object,
};

export default Input;
