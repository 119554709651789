import React from 'react';
import Carousel from 'react-material-ui-carousel'
import {Paper, Button} from '@mui/material'
import PropTypes from "prop-types";
import {CarouselType} from "./const/const";
import styles from './ImageCarousel.module.css'

const CarouselItem = (props) => {
    const {image, description, type} = props;
    return (
        <Paper elevation={12} sx={{width: 'fit-content', margin: 'auto', height: '20rem'}}>
            <img className={styles.image} src={image} alt={'Not Found'}/>
            {description && <p>{description}</p>}
        </Paper>
    )
}

const ImageCarousel = (props) => {
    const {items, type = CarouselType.ImageOnly, autoPlay = false, sx} = props;

    return (
        <Carousel
            autoPlay={autoPlay}
            sx={{width: '100%', height: '100%', padding: '1rem', ...sx}}
        >
            {
                items.map((item, i) => <CarouselItem type {...item} />)
            }
        </Carousel>
    )
}

ImageCarousel.propsType = {
    autoPlay: PropTypes.bool,
};

export {ImageCarousel, CarouselItem};
