import React, {useMemo} from 'react';
import Header from './shared/component/header/Header';
import MainPage from './pages/main-page/MainPage';
import {Routes as RoutesConst} from './shared/const/routes';

import logo from './logo.svg';
import './App.css';
import {BrowserRouter, Route, Routes, useLocation} from 'react-router-dom';
import Footer from './shared/component/footer/Footer';
import { inject } from '@vercel/analytics';
// main about info contacts order
inject();
function App() {
    return (
        <BrowserRouter>
            <div className='App'>
                <Header/>
                <Routes>
                    {useMemo(() => RoutesConst.map(({path, component}, i) => {
                        return <Route path={path} element={component} key={i}/>;
                    }), [RoutesConst])}
                </Routes>
                <Footer/>
            </div>
        </BrowserRouter>

    );
}

export default App;
