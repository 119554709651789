import Contacts from "./Contacts";
import {YMaps} from "react-yandex-maps";
import React from "react";

const ContactsWrapper = () => (
    <YMaps>
        <Contacts/>
    </YMaps>
);
export default ContactsWrapper;
