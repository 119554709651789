export const AboutUsTextConsts = [
  `Байкальский центр судебных экспертиз и графоанализа, являясь
          независимой экспертной организацией, на высоком профессиональном
          уровне осуществляет производство независимых судебных экспертиз по
          уголовным, гражданским, арбитражным делам и делам об административных
          правонарушениях. Байкальский центр судебных экспертиз и графоанализа
          осуществляет свою деятельность с апреля 2015 года. Организация
          специализируется на выполнении сложных и трудоёмких исследований с
          применением самых современных методик.`,
  `В штате организации состоят и
          оказывают услуги эксперты, имеющие высшее образование, опыт и
          многолетний стаж работы в государственных судебно-экспертных
          учреждениях.`,
  `В целях обеспечения надлежащей проверки достоверности
          фактов, обеспечиваем участие специалистов с выездом на места по
          основным направлениям деятельности. Гарантируем проведение проверок и
          консультаций на самом высоком профессиональном уровне, по результатам
          которых будет составлено письменное заключение, выполненное в
          соответствии с требованиями законодательства РФ.`,
];

export const OurCustomers = [
  { link: 'https://irkutsk.arbitr.ru/', text: `Арбитражный суд Иркутской области` },
  { link: 'https://chita.arbitr.ru/', text: `Арбитражный суд Забайкальского края` },
  { link: 'https://amuras.arbitr.ru/', text: `Арбитражный суд Амурской области` },
  { link: 'http://oblsud.irk.sudrf.ru/', text: `Иркутский областной суд` },
  {
    link: 'https://irk.sledcom.ru/',
    text: `Следственное управление Следственного комитета РФ по Иркутской области`,
  },
  { link: 'https://stu.customs.gov.ru/folder/12098', text: `Иркутская таможня` },
  {
    link: 'https://dvtu.customs.gov.ru/',
    text: `Дальневосточное таможенное управление ФТС России, Благовещенская таможня`,
  },
  { link: 'https://www.google.com/search?q=%D0%B2%D0%BE%D0%B5%D0%BD%D0%BD%D0%B0%D1%8F+%D0%BF%D1%80%D0%BE%D0%BA%D1%83%D1%80%D0%B0%D1%82%D1%83%D1%80%D0%B0+%D0%B7%D0%B0%D0%B1%D0%B0%D0%B9%D0%BA%D0%B0%D0%BB%D1%8C%D1%81%D0%BA%D0%BE%D0%B3%D0%BE+%D0%BA%D1%80%D0%B0%D1%8F&npsic=0&rflfq=1&rlha=0&rllag=52028057,113510837,803&tbm=lcl&ved=2ahUKEwjpoaDC0f7qAhXvkYsKHc0IDAkQtgN6BAgLEAQ&rldoc=1#rlfi=hd:;si:16236213157688996623;mv:[[52.038219002885604,113.53698751163229],[52.01545747934905,113.47261449527487],null,[52.02683968922074,113.50480100345358],15]', text: `Военная прокуратура Забайкальского края` },
];

export const OurCustomersAnd = `А также городские и районные суды г. Иркутска и Иркутской области, областные, городские и районные суды Красноярского края, г. Благовещенска и Амурской области, юридические отделы предприятий и организаций, нотариальные конторы, адвокатские палаты.`;
