import classNames from 'classnames';

import LU from '../../assets/images/partners/1.jpg';
import PU from '../../assets/images/partners/2.jpg';
import IH from '../../assets/images/partners/3.jpg';
import RGY from '../../assets/images/partners/4.jpg';
import ILU from '../../assets/images/partners/5.jpg';
import RPAU from '../../assets/images/partners/6.jpg';

import Certificate from '../../assets/images/certificate.jpg';
import Letter from '../../assets/images/letter.jpg';
import Worker from '../../assets/images/worker.jpg';
import Card from '../../shared/component/card/Card';
import {EmployeeType, Employees} from '../../shared/const/employes';
import styles from './About.module.css';
import {
    AboutUsTextConsts,
    OurCustomers,
    OurCustomersAnd,
} from './const/text-const';
import Tooltip from "../../shared/component/tooltip/Tooltip";
import Modal from "../../shared/component/modal/Modal";
import React, {useState} from "react";
import {IconButton} from "@mui/material";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faXmark} from "@fortawesome/free-solid-svg-icons/faXmark";

const About = (props) => {
    const [isOpen, setShowModal] = useState(false);
    const [currentEmployer, setCurrentEmployer] = useState(null);
    const handleClose = () => {
        setShowModal(false);
        setCurrentEmployer(null);
    }

    const about = () => (
        <div className={classNames(styles.aboutContainer, 'container')}>
            <p className='title'>О КОМПАНИИ</p>
            <div className={classNames(styles.aboutTextsAndImage, 'block')}>
                <div className={styles.textItems}>
                    {AboutUsTextConsts.map((textItem, i) => (
                        <p key={i}>{textItem}</p>
                    ))}
                </div>
                {/* <div className={styles.img}></div> */}
                <img src={Worker} className={styles.worker} alt='Not found'/>
            </div>
        </div>
    );

    const images = () => {
        return (
            <div className={classNames(styles.imageContainer, 'container')}>
                <img src={Certificate} className={styles.imgAbout} alt='Not found'/>
                <img src={Letter} className={styles.imgAbout} alt='Not found'/>
            </div>
        );
    };

    const stuff = () => {
        const openModal = (employee) => {
            setShowModal(true);
            setCurrentEmployer(employee);
        };

        return (
            <>
                <div className={classNames(styles.employers, 'container')}>
                    <p className='title'>Руководство БЦСЭиГ</p>
                    <div className={classNames(styles.list, 'block')}>
                        {Employees.filter((e) => e.level == EmployeeType.employer).map(
                            (employer, i) => (
                                <Tooltip callback={() => openModal(employer)}>
                                    <Card {...employer} boxWidth='36rem' type='userCard' key={employer.fullName}/>
                                </Tooltip>
                            )
                        )}
                    </div>
                </div>
                <div className={classNames(styles.employes, 'container')}>
                    <p className='title'>наши эксперты</p>
                    <div className={classNames(styles.list, 'block')}>
                        {Employees.filter((e) => e.level == EmployeeType.employee).map(
                            (employee, i) => (
                                <Tooltip callback={() => openModal(employee)}>
                                    <Card {...employee} boxWidth='36rem' type='userCard' key={employee.fullName}/>
                                </Tooltip>

                            )
                        )}
                    </div>
                </div>
            </>
        );
    };

    const partnersAndCustomers = () => {
        const partnerImages = [LU, PU, IH, RGY, ILU, RPAU];
        return (
            <div className={classNames(styles.PCContainer, 'container')}>
                <div className={classNames(styles.PC)}>
                    <p
                        className={classNames(
                            styles.PCTitle,
                            'title light-blue'
                        )}
                    >
                        наши партнеры
                    </p>
                    <p className={styles.PCTitleText}>
                        В рамках научно-исследовательской деятельности мы сотрудничаем с
                        ведущими специалистами крупнейших в регионе институтов и
                        университетов:
                    </p>
                    <div className={styles.partnersImages}>
                        {partnerImages.map((i) => (
                            <img className={styles.partnerImage} alt={'Not Found'} src={i}/>
                        ))}
                    </div>
                </div>
                <div className={styles.PC}>
                    <p
                        className={classNames(
                            styles.PCTitle,
                            'title ' +
                            'light-blue'
                        )}
                    >
                        наши заказчики
                    </p>
                    <p className={styles.PCTitleText}>
                        Нашими постоянными заказчиками являются различные крупные
                        организации и предприятия региона:
                    </p>
                    <div className={styles.customerList}>
                        {OurCustomers.map((el) => (
                            <Card {...el} type='text' backgroundColor='#DDEBFF'/>
                        ))}
                    </div>
                    <p className={styles.PCTitleText}>
                        {OurCustomersAnd}
                    </p>
                    <p></p>
                </div>
            </div>
        );
    };

    return (
        <>
            <Modal
                isOpen={isOpen}
                handleClose={handleClose}
            >
                <div className={classNames('block', styles.modalContent)}>
                    <p className="title">
                        {currentEmployer && currentEmployer.fullName}
                    </p>

                    <p className={styles.description}>
                        {currentEmployer && currentEmployer.description}
                    </p>
                </div>
            </Modal>
            {about()}
            {images()}
            {stuff()}
            {partnersAndCustomers()}
        </>
    );
};

export default About;
